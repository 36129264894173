import React, { useEffect, useState } from "react";
import Search from "../components/Search.com";
import TableData from "../components/TableData";
import TableHead from "../components/TableHead";
import { MdContactPage } from "react-icons/md";
import ModalCom from "../components/Modal.com";
import CustomePackageDetails from "../components/CustomePackageDetails";
import Pagination from "../components/Pagination";
import { useDispatch, useSelector } from "react-redux";
import { confirmAlert } from "react-confirm-alert";
import opthionsAlert from "../helper/opthionConfirmAlert";
import {
  deleteContactUs,
  getContactUs,
  setDetailsContactUs,
} from "../redux/slicers/contactUs";
import { toast, ToastContainer } from "react-toastify";
import Loading from "../components/Loading";
import Error from "../components/Error.com";

function ContactUs() {
  const dispatch = useDispatch();
  const { data, loading, detailsContactUs , error} = useSelector(
    (state) => state.contactUs
  );
  const [pageNumper, setPageNumper] = useState(1);

  useEffect(() => {
    const controler = new AbortController()
    dispatch(getContactUs({pageNumper, signal:controler.signal}))

    return ()=>{
      controler.abort()
    }
  }, [dispatch, pageNumper]);

  const [modalDetails, setModalDetails] = useState(false);
  const handelShowDetailsModal = () => setModalDetails((prev) => !prev);
  const handelDetailsData = (item) => {
    dispatch(setDetailsContactUs(item));
    handelShowDetailsModal();
  };

  const handelDeleteContact = (id) => {
    confirmAlert(
      opthionsAlert("Delete", "Are You Delete This Pakege", () => {
        dispatch(deleteContactUs(id))
          .unwrap()
          .then((res) => toast.success("Done Delete Contact Message"))
          .catch((err) => toast.error(err));
      })
    );
  };
  return (
    <div className="fadeIn p-5">
      <>
        <ToastContainer
          position="top-center"
          autoClose={2000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
          theme="light"
        />
        {/* Start Title  */}
        <div className="flex items-center">
          <span>
            <MdContactPage size={35} className="text-main" />
          </span>
          <span className="px-3 font-medium text-2xl">Contact Us</span>
        </div>

        {/* Start Serch And Filter  */}
        <div className="sm:flex-wrap sm:justify-center sm:flex md:justify-between pt-5">
          <Search placeholder={"Enter Name User"} />
        </div>

        {/* Start Table  */}
        {error && <Error message={error.message} />}
        {loading && !error && <Loading />}
        {!loading && !error && <>
        <TableHead titleArrary={["N", "Name", "email", "Message", "Option"]}>
          {!loading &&
            data.data &&
            data.data.map((item, index) => (
              <TableData
                number={index + 1}
                one={item.userName}
                two={item.emailAddress}
                three={item.message}
                five={""}
                key={item._id}
                detailsBtn={() => handelDetailsData(item)}
                showBtnRemove={true}
                deleteBtn={() => handelDeleteContact(item._id)}
              />
            ))}
        </TableHead>
        </>}
        {!error && <Pagination
          totalPageNumper={data.numOfPage}
          setPageNumper={setPageNumper}
          pageNumper={pageNumper}
        />}
      </>

      <ModalCom
        onClose={handelShowDetailsModal}
        visible={modalDetails}
        icon={<MdContactPage size={25} className="text-main" />}
        title="Details Contact Us"
        closeOnBackDroupPress={true}
      >
        <CustomePackageDetails data={detailsContactUs} />
      </ModalCom>
    </div>
  );
}

export default ContactUs;
