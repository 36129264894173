import DOMPurify from "dompurify";

export const userData = JSON.parse(localStorage.getItem("user"));

export const userToken = userData?.token;

export const userId = userData?.id;

export const mainCategory = [
  { value: "Egypt tour", label: "Egypt tour" },
  { value: "Day tour", label: "Day tour" },
  { value: "Nile cruises", label: "Nile cruises" },
  { value: "Shore excursions", label: "Shore excursions" },
];

export function createMarkup(html) {
  return {
    __html: DOMPurify.sanitize(html),
  };
}

// check Valid Json
export function checkJsonIsValid(str) {
  try {
      JSON.parse(str);
  } catch (e) {
      return false;
  }
  return true;
}