export default function opthionsAlert(title , message , confirm){
   const options = {
    title,
    message,
    buttons: [
      {
        label: 'Yes',
        onClick: () => confirm()
      },
      {
        label: 'No',
        onClick: () => null
      }
    ],
    closeOnEscape: true,
    closeOnClickOutside: true,
    keyCodeForClose: [8, 32],
    willUnmount: () => {},
    afterClose: () => {},
    onClickOutside: () => {},
    onKeypress: () => {},
    onKeypressEscape: () => {},
    // overlayClassName: "overlay-custom-class-name"
  };

  return options
}