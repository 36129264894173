import React from 'react'


function TitleSectionInputs({children, message}) {
  return (
    <>
        <p className={`absolute p-1 px-4 -top-4 left-10 bg-white opacity-90 border ${message? 'border-red-300' :'border-green-300'}  rounded text-xl dark:text-black`}>
         {children}
        </p>
    </>
  )
}

export default React.memo(TitleSectionInputs)