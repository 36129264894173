import React, { useEffect, useState } from "react";
import Search from "../components/Search.com";
import TableData from "../components/TableData";
import TableHead from "../components/TableHead";
import { BsAirplaneEnginesFill } from "react-icons/bs";
import { useDispatch, useSelector } from "react-redux";
import Pagination from "../components/Pagination";
import Loading from "../components/Loading";
import Error from "../components/Error.com";
import { ToastContainer, toast } from "react-toastify";
import { deleteTrips, getAllTrips, setUsers } from "../redux/slicers/trips";
import ModalCom from "../components/Modal.com";
import { FaUsers } from "react-icons/fa";
import { confirmAlert } from "react-confirm-alert";
import opthionsAlert from "../helper/opthionConfirmAlert";

function AllTrips() {
  const dispatch = useDispatch();
  const { data, loading, error, users } = useSelector((state) => state.trips);
  const [pageNumber, setPageNumper] = useState(1);

  useEffect(() => {
    const controler = new AbortController();
    dispatch(getAllTrips({ pageNumber, signal: controler.signal }))

    return () => {
      controler.abort();
    };
  }, [dispatch, pageNumber]);

  const [modalDetails, setModalDetails] = useState(false);
  
  const handelShowDetails = (item) => {
    dispatch(setUsers(item));
    setModalDetails(true);
  };

  const handelDeleteTrip = (id)=>{
    confirmAlert(
      opthionsAlert("Delete", "Are You Delete This Trip", () => {
        dispatch(deleteTrips(id))
          .unwrap()
          .then((res) => toast.success("Done Delete Trip"))
          .catch((err) => toast.error(err.message));
      })
    );
  }

  return (
    <div className="fadeIn p-5">
      <ToastContainer
        position="top-center"
        autoClose={2000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
      />
      {/* Start Title  */}
      <div className="flex items-center">
        <span>
          <BsAirplaneEnginesFill size={35} className="text-main" />
        </span>
        <span className="px-3 font-medium text-2xl">All Trips</span>
      </div>

      {/* Start Serch And Filter  */}
      <div className="sm:flex-wrap sm:justify-center sm:flex md:justify-between pt-5">
        <Search placeholder={"Enter Name User"} />
      </div>
      {error && !loading && <Error message={error.message} />}
      {loading && <Loading />}
      {/* Start Table  */}
      {!loading && !error && data.data && (
        <>
          <TableHead
            titleArrary={[
              "N",
              "title",
              "total users",
              "check In",
              "check Out",
              "option",
            ]}
          >
            {data.data.map((item, index) => (
              <TableData
                number={index + 1}
                one={item.package?.title ? item.package.title : " "}
                two={item.users ? `${item.users.length} Users In Trip` : " "}
                four={
                  item.checkin
                    ? new Date(item.checkin).toLocaleDateString()
                    : " "
                }
                six={
                  item.checkout
                    ? new Date(item.checkout).toLocaleDateString()
                    : " "
                }
                key={item._id}
                showBtnUser={true}
                onUser={() => handelShowDetails(item.users)}
                showBtnRemove={true}
                deleteBtn={()=> handelDeleteTrip(item._id)}
              />
            ))}
          </TableHead>
        </>
      )}
      {!error && (
        <Pagination
          totalPageNumper={data.numOfPage}
          pageNumper={pageNumber}
          setPageNumper={setPageNumper}
        />
      )}

      <ModalCom
        visible={modalDetails}
        onClose={() => setModalDetails(false)}
        closeOnBackDroupPress={true}
        icon={<FaUsers size={30} className="text-main" />}
        title={"Users who Have Booked this Trip"}
      >
        <div className="max-h-[70%] overflow-auto w-full">
          <TableHead
            titleArrary={[
              "N",
              "name",
              "email",
              "phone",
              "nationality",
              "gests",
              "Itenary",
              "additionalDetails",
            ]}
          >
            {users.length !== 0 &&
              users.map((i, index) => (
                <TableData
                  key={index}
                  number={index + 1}
                  one={i.name ? i.name : " "}
                  two={i.emailAddress ? i.emailAddress : " "}
                  three={i.phoneNumber ? i.phoneNumber : " "}
                  four={i.nationality ? i.nationality : " "}
                  five={
                    i.guests
                      ? `Adults:${i.guests.numberOfAdults} / Children:${i.guests.numberOfChildren}`
                      : " "
                  }
                  six={i.itenary ? i.itenary : 'Normal Itenary'}
                  eight={i.additionalDetails ? i.additionalDetails : " "}
                  showOption={false}
                />
              ))}
          </TableHead>
        </div>
      </ModalCom>
    </div>
  );
}

export default AllTrips;
