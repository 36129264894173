import React from 'react'
import { IconHome } from '../components/Icons.com'

function Home() {
  return (
    <div className='flex justify-center items-center h-96'>
      <IconHome />
    </div>
  )
}

export default Home