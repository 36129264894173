import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import Conatiner from "../components/Conatiner";
import { useDispatch, useSelector } from "react-redux";
import "./detailsPakcage.css";
import One from "../assets/one.png";
import Two from "../assets/two.png";
import Three from "../assets/three.png";
import Foure from "../assets/foure.png";
import Five from "../assets/five.png";
import { RiCarWashingLine, RiPriceTag2Line } from "react-icons/ri";
import {
  TbBeachOff,
  TbClockHour4,
  TbPoolOff,
  TbWashTumbleOff,
} from "react-icons/tb";
import {
  MdDeleteForever,
  MdFamilyRestroom,
  MdLeakRemove,
  MdLocalLaundryService,
  MdNoFood,
  MdOutlineCalendarMonth,
  MdOutlineLocationOn,
  MdOutlineRoomService,
  MdPool,
  MdRemoveCircleOutline,
  MdRestaurantMenu,
} from "react-icons/md";
import { TiTickOutline } from "react-icons/ti";
import { BiWifi, BiWifiOff } from "react-icons/bi";
import { CgGym } from "react-icons/cg";
import { FaUmbrellaBeach } from "react-icons/fa";
import { IoIosStarHalf, IoIosStarOutline , IoIosStar } from "react-icons/io";
import { GrUpdate } from "react-icons/gr";
import { getPackageWithId, deletePackage } from "../redux/slicers/packages";
import ModalCom from "../components/Modal.com";
import UpdatePackage from "../components/UpdatePackage";
import Loading from "../components/Loading";
import opthionsAlert from "../helper/opthionConfirmAlert";
import { confirmAlert } from "react-confirm-alert";
import { ToastContainer, toast } from "react-toastify";
import Error from "../components/Error.com";
import AccordionCom from "../components/Accordion.com";
import { Accordion } from "react-accessible-accordion";
import { checkJsonIsValid } from "../helper/constants";

function DetailsPackage() {
  const params = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { packageData, loadingpackageId, error } = useSelector(
    (state) => state.packages
  );
  const [hotelActivities, setHotelActivities] = useState([]);
  useEffect(() => {
    const controler = new AbortController();
    dispatch(getPackageWithId({ id: params.id, signal: controler.signal }))

    return () => {
      controler.abort();
    };
  }, [dispatch, params]);

  useEffect(() => {
    setHotelActivities(
      packageData.hotelActivities
        ? packageData.hotelActivities.map((i) => JSON.parse(i))
        : []
    );
  }, [packageData]);

  const [activeIndex, setActiveIndex] = useState(1);
  const handleClick = (index) => setActiveIndex(index);
  const checkActive = (index, className) =>
    activeIndex === index ? className : "";

  // handel logic in packages
  const [showUpdate, setShowUpdate] = useState(false);
  const handleRemovePackage = () => {
    confirmAlert(
      opthionsAlert("Delete", "Are You Delete This Pakege", () => {
        dispatch(deletePackage(packageData._id))
          .unwrap()
          .then((res) => {
            toast.success("Done Delete Package");
            navigate(-1, { replace: true });
          })
          .catch((err) => toast.error(err));
      })
    );
  };
  const handleUpdatePackage = () => {
    setShowUpdate(true);
  };

  return (
    <div>
      <ToastContainer
        position="top-center"
        autoClose={2000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
      />
      {error && !loadingpackageId && <Error message={error.message} />}
      {loadingpackageId && <Loading />}
      {!loadingpackageId && !error && packageData && (
        <Conatiner>
          <div className="">
            {/* Start Images  */}
            {packageData.images && (
              <div className="relative overflow-hidden h-80 mb-5 py-10">
                <div className="w-44 h-28 bg-main-50 absolute left-0 top-32 z-0"></div>
                <div className="w-1/2 h-28 bg-main-50 absolute right-0 top-0 z-0"></div>
                <div className="w-11/12 m-auto z-10 h-full relative">
                  <div className="flex h-full">
                    <div className="h-full w-[30%]">
                      <img
                        src={
                          packageData?.images[0]
                            ? packageData.images[0]
                            : One
                        }
                        className="object-cover h-full w-full rounded-l-md"
                        alt=""
                      />
                    </div>

                    <div className="h-full w-[35%]">
                      <div className="h-1/2 w-full">
                        <img
                          src={
                            packageData?.images[1]
                              ? packageData.images[1]
                              : Two
                          }
                          alt=""
                          className="object-cover h-full w-full"
                        />
                      </div>
                      <div className="h-1/2 w-full">
                        <img
                          src={
                            packageData?.images[2]
                              ? packageData.images[2]
                              : Three
                          }
                          alt=""
                          className="object-cover h-full w-full"
                        />
                      </div>
                    </div>

                    <div className="h-full w-[35%]">
                      <div className="h-1/2 w-full">
                        <img
                          src={
                            packageData?.images[3]
                              ? packageData.images[3]
                              : Foure
                          }
                          alt=""
                          className="object-cover h-full w-full rounded-tr-md"
                        />
                      </div>
                      <div className="h-1/2 w-full">
                        <img
                          src={
                            packageData?.images[4]
                              ? packageData.images[4]
                              : Five
                          }
                          alt=""
                          className="object-cover h-full w-full rounded-br-md"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            )}
            {/* End Images  */}
            {/* Start Navbar  */}
            <div>
              <ul className="flex bg-slate-200 dark:text-black px-3 gap-4 py-1 rounded">
                <li
                  className={`cursor-pointer px-2 py-1 tab ${checkActive(
                    1,
                    "activeted"
                  )}`}
                  onClick={() => handleClick(1)}
                >
                  Overview
                </li>
                <li
                  className={`cursor-pointer px-2 py-1 tab ${checkActive(
                    2,
                    "activeted"
                  )}`}
                  onClick={() => handleClick(2)}
                >
                  Highlights
                </li>
                <li
                  className={`cursor-pointer px-2 py-1 tab ${checkActive(
                    3,
                    "activeted"
                  )}`}
                  onClick={() => handleClick(3)}
                >
                  Inclusions
                </li>
                <li
                  className={`cursor-pointer px-2 py-1 tab ${checkActive(
                    4,
                    "activeted"
                  )}`}
                  onClick={() => handleClick(4)}
                >
                  Reviews
                </li>
              </ul>
            </div>
            {/* End Navbar  */}
            {/* Start Body  */}
            <div className="">
              {/* Overview */}
              <div className={`panel ${checkActive(1, "activeted fadeIn")}`}>
                <div className="flex gap-1 py-1 h-full">
                  <div className="bg-slate-200 px-5 pt-5 rounded w-7/12">
                    <h2 className="text-blue-900 py-4 text-xl font-medium">
                      {packageData?.title}
                    </h2>
                    <p className="text-gray-600 pb-2 text-[15px] max-h-[70%] overflow-auto">
                      {packageData?.descrption}
                    </p>
                  </div>
                  <div className="bg-slate-200 px-3 pt-5 rounded w-5/12">
                    <h2 className="text-blue-900 py-4 text-xl font-medium">
                      Toure Details
                    </h2>
                    <div>
                      <div className="flex text-gray-600 overflow-hidden mb-3 text-[15px]">
                        <div className="flex w-1/2 items-center gap-1 ">
                          <span>
                            <RiPriceTag2Line size={20} />
                          </span>
                          <span>Price</span>
                        </div>
                        <div className="w-1/2">{packageData?.price + " $"}</div>
                      </div>
                      <div className="flex text-gray-600 overflow-hidden mb-3 text-[15px]">
                        <div className="flex w-1/2 items-center gap-1 ">
                          <span>
                            <TbClockHour4 size={20} />
                          </span>
                          <span>Duration</span>
                        </div>
                        <div className="w-1/2">
                          {packageData.days
                            ? packageData.days
                            : ""}
                        </div>
                      </div>
                      <div className="flex text-gray-600 overflow-hidden mb-3 text-[15px]">
                        <div className="flex w-1/2 items-center gap-1 ">
                          <span>
                            <MdOutlineLocationOn size={20} />
                          </span>
                          <span>Tour Location</span>
                        </div>
                        <div className="w-1/2">
                          {packageData.location &&
                            packageData.location.map((i, index) => (
                              <p className="inline" key={index}>
                                {+packageData.location.findLastIndex(
                                  (i) => i
                                ) === +index
                                  ? i.name
                                  : i.name + "-"}
                              </p>
                            ))}
                        </div>
                      </div>
                      <div className="flex text-gray-600 overflow-hidden mb-3 text-[15px]">
                        <div className="flex w-1/2 items-center gap-1 ">
                          <span>
                            <MdOutlineCalendarMonth size={20} />
                          </span>
                          <span>Tour Avaliability</span>
                        </div>
                        <div className="w-1/2">{packageData?.availablitiy}</div>
                      </div>
                      <div className="flex text-gray-600 overflow-hidden mb-3 text-[15px]">
                        <div className="flex w-1/2 items-center gap-1 ">
                          <span>
                            <RiCarWashingLine size={20} />
                          </span>
                          <span>Drope-off</span>
                        </div>
                        <div className="w-1/2">{packageData?.pick_drop}</div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              {/* Highlights */}
              <div className={`panel ${checkActive(2, "activeted fadeIn")}`}>
                <div className="flex gap-1 py-1 h-full">
                  <div className="bg-slate-200 px-5 rounded w-full overflow-y-auto py-5">
                    <div className="flex flex-col">
                      <Accordion
                        className="border-0"
                        allowMultipleExpanded={false}
                      >
                        {packageData.highlights &&
                          packageData.highlights.map((i, index) => {
                            const item = checkJsonIsValid(i) ? JSON.parse(i) : i ;
                            return (
                              <AccordionCom
                                key={index}
                                header={item?.mainAddres}
                                content={item?.subAddress}
                              />
                            );
                          })}
                      </Accordion>
                    </div>
                  </div>
                </div>
              </div>

              {/* Inclusions */}
              {hotelActivities.length !== 0 && (
                <div className={`panel ${checkActive(3, "activeted fadeIn")}`}>
                  <div className="flex gap-1 py-1 h-full">
                    <div className="bg-slate-200 px-5 pt-5 rounded w-7/12 overflow-y-auto">
                      <div>
                        <h3 className="text-blue-900 py-2 text-xl font-medium">
                          Tour Inclusions
                        </h3>
                        {packageData.inclusions &&
                          packageData.inclusions.map((item, index) => (
                            <p
                              key={index}
                              className="flex gap-1 mb-1"
                            >
                              <span>
                                <TiTickOutline
                                  size={25}
                                  className="text-green-500"
                                />
                              </span>
                              <span className="text-[14px] text-gray-600 border-b border-white pb-2">
                                {item}
                              </span>
                            </p>
                          ))}
                      </div>
                      <div>
                        <h3 className="text-blue-900 py-2 text-xl font-medium">
                          Tour Exclusions
                        </h3>
                        {packageData?.exclusions &&
                          packageData?.exclusions.map((item, index) => (
                            <p
                              key={index}
                              className="flex gap-1 mb-1"
                            >
                              <span>

                                <MdRemoveCircleOutline
                                  size={25}
                                  className="text-red-500"
                                />
                              </span>
                              <span className="text-[14px] text-gray-600 border-b border-white pb-2">
                                {item}
                              </span>
                            </p>
                          ))}
                      </div>
                    </div>
                    <div className="bg-slate-200 px-3 pt-5 rounded w-5/12">
                      <h2 className="text-blue-900 py-4 text-xl font-medium">
                        Toure Details
                      </h2>
                      <div className="flex mb-4 text-gray-600">
                        <div className="flex w-1/2 items-center gap-2 ">
                          <span>
                            {hotelActivities[0]?.wifi ? (
                              <BiWifi size={25} />
                            ) : (
                              <BiWifiOff size={25} />
                            )}
                          </span>
                          <span
                            className={`${
                              !hotelActivities[0].wifi && "line-through"
                            }`}
                          >
                            WiFi
                          </span>
                        </div>
                        <div className="flex w-1/2 items-center gap-2 ">
                          <span>
                            {hotelActivities[1].pool ? (
                              <MdPool size={25} />
                            ) : (
                              <TbPoolOff size={25} />
                            )}
                          </span>
                          <span
                            className={`${
                              !hotelActivities[1].pool && "line-through"
                            }`}
                          >
                            Pool
                          </span>
                        </div>
                      </div>
                      <div className="flex mb-4 text-gray-600">
                        <div className="flex w-1/2 items-center gap-2 ">
                          <span>
                            {hotelActivities[2].laundry ? (
                              <MdLocalLaundryService size={25} />
                            ) : (
                              <TbWashTumbleOff size={25} />
                            )}
                          </span>
                          <span
                            className={`${
                              !hotelActivities[2].laundry && "line-through"
                            }`}
                          >
                            Laundry
                          </span>
                        </div>
                        <div className="flex w-1/2 items-center gap-2 ">
                          <span>
                            {hotelActivities[3].gym ? (
                              <CgGym size={25} />
                            ) : (
                              <MdLeakRemove size={25} />
                            )}
                          </span>
                          <span
                            className={`${
                              !hotelActivities[3].gym && "line-through"
                            }`}
                          >
                            GYM
                          </span>
                        </div>
                      </div>
                      <div className="flex mb-4 text-gray-600">
                        <div className="flex w-1/2 items-center gap-2 ">
                          <span>
                            {hotelActivities[4].restaurant ? (
                              <MdRestaurantMenu size={25} />
                            ) : (
                              <MdNoFood size={25} />
                            )}
                          </span>
                          <span
                            className={`${
                              !hotelActivities[4].restaurant && "line-through"
                            }`}
                          >
                            Restaurant
                          </span>
                        </div>
                        <div className="flex w-1/2 items-center gap-2 ">
                          <span>
                            {hotelActivities[5].beachfront ? (
                              <FaUmbrellaBeach size={25} />
                            ) : (
                              <TbBeachOff size={25} />
                            )}
                          </span>
                          <span
                            className={`${
                              !hotelActivities[5].beachfront && "line-through"
                            }`}
                          >
                            Beachfront
                          </span>
                        </div>
                      </div>
                      <div className="flex mb-4 text-gray-600">
                        <div className="flex w-1/2 items-center gap-2 ">
                          <span>
                            <MdOutlineRoomService size={25} />
                          </span>
                          <span
                            className={`${
                              !hotelActivities[6].romService && "line-through"
                            }`}
                          >
                            Room Service
                          </span>
                        </div>
                        <div className="flex w-1/2 items-center gap-2 ">
                          <span>
                            <MdFamilyRestroom size={25} />
                          </span>
                          <span
                            className={`${
                              !hotelActivities[7].familyRooms && "line-through"
                            }`}
                          >
                            Family Rooms
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              )}

              {/* Review */}
              <div className={`panel ${checkActive(4, "activeted fadeIn")}`}>
                <div className="flex gap-1 py-1 h-full">
                  <div className="bg-slate-200 px-5 pt-5 rounded w-full overflow-y-auto">
                    <div className="flex justify-between  pb-7">
                      <h2 className="text-blue-900 text-xl font-medium">
                        Review
                      </h2>
                    </div>
                    {packageData.reviewes && packageData.reviewes.map((item, index) => (
                      <div key={index} className="border-b-2 border-gray-300 mb-4 pb-2">
                        <div className="w-2/3 flex justify-between items-center mb-1">
                          <span className="text-blue-900 font-medium tex-[15px]">
                            {item?.userName}
                          </span>
                          <ul className="flex items-center">
                            {Array.from({length : 5} ,(_ , index)=>{
                              let number = index + 0.5
                              return(
                                <>
                                  {
                                    item.rate >= index + 1 ? 
                                      <li className="text-[#fbb200]" key={index + 5}>
                                        <IoIosStar />
                                      </li>
                                    : item.rate >= number ?
                                      <li className="text-[#fbb200]" key={index + .40}>
                                        <IoIosStarHalf />
                                      </li>
                                    :
                                      <li className="text-[#fbb200]" key={index + 2}>
                                        <IoIosStarOutline />
                                      </li>
                                      
                                  }
                                </>
                              )
                            }) }
                          </ul>
                        </div>
                        <div className="w-5/6 max-h-12 overflow-hidden">
                          <p className="text-gray-600 text-sm" title="lol">
                            {item?.comment}
                          </p>
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
              </div>
            </div>
            {/* End Body  */}
            <div className="flex items-center gap-2 my-4">
              <div
                onClick={() => handleRemovePackage()}
                className="flex-1 py-2 border-2 border-error bg-error rounded cursor-pointer hover:bg-red-200 dark:hover:text-black duration-500"
              >
                <div className="flex items-center justify-center gap-3">
                  <span>
                    <MdDeleteForever size={30} />
                  </span>
                  <span>Remove The Package</span>
                </div>
              </div>
              <div
                onClick={() => handleUpdatePackage()}
                className="flex-1 py-2 border-2 border-blue-400 bg-blue-400 rounded cursor-pointer hover:bg-blue-200 dark:hover:text-black duration-500"
              >
                <div className="flex items-center justify-center gap-3">
                  <span>
                    <GrUpdate size={30} />
                  </span>
                  <span>Update The Package</span>
                </div>
              </div>
            </div>
          </div>
        </Conatiner>
      )}

      <ModalCom
        onClose={() => setShowUpdate(false)}
        visible={showUpdate}
        title={"Update Package"}
      >
        <UpdatePackage onClose={() => setShowUpdate(false)} />
      </ModalCom>
    </div>
  );
}

export default DetailsPackage;
