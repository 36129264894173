import { createSlice , createAsyncThunk} from '@reduxjs/toolkit'
import AxiosInstance from '../AxiosInstance'

export const getAllLocation = createAsyncThunk('location/all',
  async (_ , { rejectWithValue })=>{
    try {
      const { data } = await AxiosInstance.get('location')

      return data
    } catch (error) {
      return rejectWithValue(error.response ? error.response.data : error)
    }
  }
)

export const getLocationsWithPageNum = createAsyncThunk('location/:num',
  async ({pageNumber , signal}, { rejectWithValue })=>{
    try {
      const { data } = await AxiosInstance.get(`location?pageNumber=${pageNumber}` , {signal})

      return data
    } catch (error) {
      return rejectWithValue(error.response ? error.response.data : error)
    }
  }
)

export const deleteLocation = createAsyncThunk('location/delete',
  async (id , { rejectWithValue })=>{
    try {
      // eslint-disable-next-line no-unused-vars
      const { data } = await AxiosInstance.delete(`location/${id}`)

      return id
    } catch (error) {
      return rejectWithValue(error.response ? error.response.data : error)
    }
  }
)

export const addLocation = createAsyncThunk('location/add',
  async (locationData , { rejectWithValue })=>{
    try {

      const { data } = await AxiosInstance.post(`location/create` , locationData)

      return data
    } catch (error) {
      return rejectWithValue(error.response ? error.response.data : error)
    }
  }
)

export const updateLocation = createAsyncThunk('location/update',
  async ({id , locationData} , { rejectWithValue , dispatch })=>{
    try {

      // eslint-disable-next-line no-unused-vars
      const { data } = await AxiosInstance.put(`location/${id}` , locationData)
      // dispatch(getLocationsWithPageNum())
      return { id , locationData}
    } catch (error) {
      return rejectWithValue(error.response ? error.response.data : error)
    }
  }
)

export const searchLocation = createAsyncThunk('location/search',
  async ( searchData  , { rejectWithValue })=>{
    try {
      const { data } = await AxiosInstance.get(`location/search/${searchData}`)
      console.log(data)
      return data
    } catch (error) {
      return rejectWithValue(error.response ? error.response.data : error )
    }
  }
)


const locationSlice = createSlice({
  name:"location",
  initialState:{
    dataOption:[],
    data:[],
    isLoading:false,
    error:null
  },
  extraReducers: (builder) => {
    builder
    // get All location
    // #region
    .addCase(getAllLocation.pending, (state) => {
      state.isLoading = true;
    })
    .addCase(getAllLocation.fulfilled, (state, action) => {
      state.isLoading = false;
      state.dataOption = action.payload.data;
    })
    .addCase(getAllLocation.rejected, (state, action) => {
      state.isLoading = false;
      state.error = action.payload;
    })
    // #endregion

    // get location with pagination
    // #region
    .addCase(getLocationsWithPageNum.pending, (state) => {
      state.isLoading = true;
    })
    .addCase(getLocationsWithPageNum.fulfilled, (state, action) => {
      state.isLoading = false;
      state.data = action.payload;
      state.error = null
    })
    .addCase(getLocationsWithPageNum.rejected, (state, action) => {
      state.isLoading = false;
      state.error = action.payload;
      // eslint-disable-next-line eqeqeq
      if(action.payload.status == 404 ){
        state.data = {data:[]}
      }
    })
    // #endregion
    
    // delete location 
    // #region
    .addCase(deleteLocation.pending, (state) => {
      state.isLoading = true;
    })
    .addCase(deleteLocation.fulfilled, (state, action) => {
      state.isLoading = false;
      state.data.data = state.data.data.filter((i)=> i._id !== action.payload);
      state.error = null
    })
    .addCase(deleteLocation.rejected, (state, action) => {
      state.isLoading = false;
      state.error = action.payload;
    })
    // #endregion

    // add location 
    // #region
    .addCase(addLocation.pending, (state) => {
      state.isLoading = true;
    })
    .addCase(addLocation.fulfilled, (state, action) => {
      state.isLoading = false;
      state.data.data = [...state.data?.data , action.payload?.data] 
      state.error = null
    })
    .addCase(addLocation.rejected, (state, action) => {
      state.isLoading = false;
      state.error = action.payload;
    })
    // #endregion

    // update location 
    // #region
    .addCase(updateLocation.pending, (state) => {
      state.isLoading = true;
    })
    .addCase(updateLocation.fulfilled, (state, action) => {
      state.isLoading = false;
      state.data.data = state.data.data.map((i)=>{
        // eslint-disable-next-line eqeqeq
        if(i._id == action.payload.id){
          return {...i , name:action.payload.locationData.name}
        }
        else{
          return i
        }
      })
      state.error = null
    })
    .addCase(updateLocation.rejected, (state, action) => {
      state.isLoading = false;
      state.error = action.payload;
    })
    // #endregion
  
    // Search 
    // #region
    .addCase(searchLocation.pending , (state , action) =>{
      state.isLoading = true
    })
    .addCase(searchLocation.fulfilled , (state , action) =>{
      state.isLoading = false
      state.data.data = action.payload.data
    })
    .addCase(searchLocation.rejected , (state , action) =>{
      state.isLoading = false
      state.error = action.payload
    })
    // #endregion
  }
})



export default locationSlice.reducer