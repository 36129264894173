import React, { useCallback, useEffect, useState } from "react";
import Search from "../components/Search.com";
import TableData from "../components/TableData";
import TableHead from "../components/TableHead";
import { MdAddLocationAlt, MdLocationOn } from "react-icons/md";
import { MdOutlineUpdate } from "react-icons/md";
import { RxUpdate } from "react-icons/rx";
import Input from "../components/Input.com";
import { useForm } from "react-hook-form";
import ErrorValidation from "../components/addPackage/ErrorValidation";
import { useDispatch, useSelector } from "react-redux";
import { toast, ToastContainer } from "react-toastify";
import opthionsAlert from "../helper/opthionConfirmAlert";
import { confirmAlert } from "react-confirm-alert";
import Pagination from "../components/Pagination";
import Loading from "../components/Loading";
import Error from "../components/Error.com";
import ModalCom from "../components/Modal.com";
import Button from "../components/Button.com";
import { addLocation, deleteLocation, getLocationsWithPageNum, searchLocation, updateLocation } from "../redux/slicers/location";

function Location() {
  const dispatch = useDispatch();
  const { data, isLoading:loading , error } = useSelector((state) => state.location);
  const [pageNumper, setPageNumper] = useState(1);
  useEffect(() => {
    const controler = new AbortController();
    dispatch(getLocationsWithPageNum({ pageNumber : pageNumper, signal: controler.signal }))
    
    return () => {
      controler.abort();
    };
  }, [dispatch, pageNumper]);
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm({
    defaultValues: { name: "" },
  });
  const handelDeleteCat = useCallback(
    (id) => {
      confirmAlert(
        opthionsAlert("Delete", "Are You Delete This Location", () => {
          dispatch(deleteLocation(id))
            .unwrap()
            .then((res) => {
              toast.success("Done Delete Location");
            })
            .catch((err) => toast.error(err.message));
        })
      );
    },
    [dispatch]
  );

  // add location
  const onSubmit = (data) => {
    dispatch(addLocation(data))
      .unwrap()
      .then((res) => {
        if (res) {
          reset();
          toast.success("Done Add Location");
        }
      })
      .catch((err) => {
        toast.error(err.message);
      });
  };

  // Start Update
  const [modalUpdate, setModalUpdate] = useState(false);
  const [updateData, setUpdateData] = useState({});
  const {
    register: updateRegister,
    handleSubmit: updateSupmit,
    formState: { errors: updateErr },
    reset: resetUpdate,
  } = useForm();
  const handelBtnUpdate = (item) => {
    setUpdateData(item);
    setModalUpdate(true);
  };

  const handelUpdate = (data) => {
    const sendData = {
      id: updateData._id,
      locationData:data,
    };
    dispatch(updateLocation(sendData))
      .unwrap()
      .then(() => {
          toast.success("Done Update Location");
          setModalUpdate(false);
      })
      .catch((err) => toast.error(err.message));
  };

  const handelSearch = ({ title }) => {
    const handelTitle = title.trim();
    console.log(handelTitle);
    dispatch(searchLocation(handelTitle))
      .unwrap()
      .then((res) => console.log(res));
  };
  return (
    <div className="fadeIn p-5">
      <>
        <ToastContainer
          position="top-center"
          autoClose={2000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
          theme="light"
        />
        {/* Start Title  */}
        <div className="flex items-center">
          <span>
            <MdLocationOn size={35} className="text-main" />
          </span>
          <span className="px-3 font-medium text-2xl">Locations</span>
        </div>

        {/* Start Serch And Filter  */}
        <div className="sm:flex-wrap sm:justify-center sm:flex md:justify-between items-center pt-5 w-[96%]">
          <form
            onSubmit={handleSubmit(onSubmit)}
            className="flex flex-row justify-between items-baseline w-full md:w-1/2 lg:w-1/2 xl:w-1/2"
          >
            <div className="w-[60%]">
              <Input
                placeholder={"Enter Name location"}
                icon={<MdAddLocationAlt size={20} className="text-main" />}
                type="text"
                register={{
                  ...register("name", {
                    required: { value: true, message: "The Name Is Required" },
                  }),
                }}
              />
              <ErrorValidation message={errors.name?.message} mt={"mt-2"} />
            </div>
            <Button value="Add Location" type="submit" />
          </form>
          <Search placeholder={"Enter Name"} onSubmit={handelSearch} />
        </div>

        {error && <Error message={error.message} />}
        {loading && <Loading />}
        {/* Start Table  */}
        {!loading && !error && (
          <>
            <div className="w-full flex justify-center items-center">
              <div className="w-[80%]">
                <TableHead
                  titleArrary={["N", "Name", "Option"]}
                  widthCeilOne={"w-[75%]"}
                >
                  {!loading &&
                    data?.data &&
                    data?.data.map((item, index) => (
                      <TableData
                        number={index + 1}
                        one={item?.name}
                        two={""}
                        key={item?._id}
                        deleteBtn={() => handelDeleteCat(item._id)}
                        showBtnUpdate={true}
                        onUpdate={() => handelBtnUpdate(item)}
                        showBtnRemove={true}
                      />
                    ))}
                </TableHead>

              </div>
            </div>
          </>
        )}
        {!error && <Pagination
          totalPageNumper={data?.numOfPage}
          setPageNumper={setPageNumper}
          pageNumper={pageNumper}
        />}

        <ModalCom
          onClose={() => {
            setUpdateData("");
            resetUpdate();
            setModalUpdate(false);
          }}
          icon={<MdOutlineUpdate size={25} className="text-main" />}
          title={`Update ${updateData.name}`}
          visible={modalUpdate}
        >
          <form
            onSubmit={updateSupmit(handelUpdate)}
            className="flex justify-around items-center"
          >
            <div>
              <Input
                icon={<RxUpdate size={20} className="text-main" />}
                defaultValue={updateData.name}
                placeholder={"Enter Name"}
                register={{
                  ...updateRegister("name", {
                    required: { value: true, message: "Please Enter Name" },
                  }),
                }}
              />
              <ErrorValidation message={updateErr.name?.message} />
            </div>
            <Button value="Update Location" type="submit" />
          </form>
        </ModalCom>
      </>
    </div>
  );
}

export default Location;
