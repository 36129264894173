import React from 'react'

function IconsSectionInput({children, textarea}) {
  // console.log('IconsSectionInput')
  return (
    <>
        <span className={`absolute left-0 ${textarea ? '-top-2 pt-2'  : 'top-0'}  bg-slate-200 dark:text-black h-full px-1 flex items-center rounded-l-md`}>
         {children}
        </span>
        
    </>
  )
}

export default React.memo(IconsSectionInput) 