import React, { useEffect, useState } from "react";
import Search from "../components/Search.com";
import TableData from "../components/TableData";
import TableHead from "../components/TableHead";
import { BiPackage } from "react-icons/bi";
import { useDispatch, useSelector } from "react-redux";
import { getAllPackages, searchPackages, sendNotificationPackage } from "../redux/slicers/packages";
import Pagination from "../components/Pagination";
import Loading from "../components/Loading";
import Error from "../components/Error.com";
import { ToastContainer, toast } from "react-toastify";
import { useNavigate } from "react-router-dom";

function AllPackages() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { data, loading, error , mailLoading } = useSelector((state) => state.packages);
  const [pageNumber, setPageNumper] = useState(1);

  useEffect(() => {
    const controler = new AbortController();
    dispatch(getAllPackages({ pageNumber, signal: controler.signal }))

    return () => {
      controler.abort();
    };
  }, [dispatch, pageNumber]);


  const handleNavigateToDetailsOnePackage = (id) => {
    navigate(`${id}`);
  };

  const handelSendNotification = (item) =>{
    dispatch(sendNotificationPackage(item))
      .unwrap().then((res)=> toast.success('Done Send Notification Successfully') )
      .catch((err)=> toast.error(err.message) )
  }

  const handelSearch = ({title}) =>{
    dispatch(searchPackages({text: title }))
  }

  return (
    <div className="fadeIn p-5">
      <ToastContainer
        position="top-center"
        autoClose={2000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
      />
      {/* Start Title  */}
      <div className="flex items-center">
        <span>
          <BiPackage size={35} className="text-main" />
        </span>
        <span className="px-3 font-medium text-2xl">All Packages</span>
      </div>

      {/* Start Serch And Filter  */}
      <div className="sm:flex-wrap sm:justify-center sm:flex md:justify-between pt-5">
        <Search placeholder={"Enter Name User"} onSubmit={handelSearch}/>
      </div>
      {error && !loading && <Error message={error.message} />}
      {loading && <Loading />}
      {/* Start Table  */}
      {!loading && !error && data.data && (
        <>
          <TableHead
            titleArrary={[
              "N",
              "title",
              "days",
              "location",
              "category",
              "price",
              "option",
            ]}
          >
            {data.data.map((item, index) => (
              <TableData
                number={index + 1}
                one={item.title ? item.title : " "}
                two={item.days ? item.days : " "}
                three={
                  item.location
                    ? item.location.map((i , index) =>( 
                        <p className="inline" key={i._id}>
                          {+item.location.findLastIndex((i)=> i) === +index ? i.name : i.name + "-"}
                        </p>
                      ))
                    : " "
                }
                four={item.category?.name ? item.category?.name : " "}
                five={item.price ? item.price + "$" : " "}
                key={index}
                showBtnDetails={true}
                showBtnNotification={true}
                detailsBtn={() => handleNavigateToDetailsOnePackage(item._id) }
                onNotification={() => handelSendNotification(item)}
                mailLoading={mailLoading}
              />
            ))}
          </TableHead>

        </>
      )}
      {!error && <Pagination
        totalPageNumper={data.numOfPage}
        pageNumper={pageNumber}
        setPageNumper={setPageNumper}
      />}
    </div>
  );
}

export default AllPackages;
