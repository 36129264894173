/* eslint-disable eqeqeq */
import { createSlice , createAsyncThunk} from '@reduxjs/toolkit'
import AxiosInstance from '../AxiosInstance'

export const getAllCategoriesWithMainName = createAsyncThunk('cat/allWithOutPag',
  async ( MainName , { rejectWithValue })=>{
    try {
      const { data } = await AxiosInstance.get(`category/getbymain/${MainName}`)

      return data
    } catch (error) {
      return rejectWithValue(error.response ? error.response.data : error )
    }
  }
)

export const addCategories = createAsyncThunk('cat/add',
  async ( name , { rejectWithValue , getState })=>{
    try {
      const { data } = await AxiosInstance.post(`category/create`, name )
      return data
    } catch (error) {
      return rejectWithValue(error.response ? error.response.data : error )
    }
  }
)

export const getAllCategories = createAsyncThunk('cat/all',
  async ( { pageNumper , signal } , { rejectWithValue })=>{
    try {
      const { data } = await AxiosInstance.get(`category?pageNumber=${pageNumper}`,{signal: signal})

      return data
    } catch (error) {
      return rejectWithValue(error.response ? error.response.data : error )
    }
  }
)

export const searchCategories = createAsyncThunk('cat/search',
  async ( searchData  , { rejectWithValue })=>{
    try {
      const { data } = await AxiosInstance.get(`category/search/${searchData}`)

      return data
    } catch (error) {
      return rejectWithValue(error.response ? error.response.data : error )
    }
  }
)

export const updateCategories = createAsyncThunk('cat/update',
  async ( { id , catigoryData } , { rejectWithValue , dispatch})=>{
    try {
      // eslint-disable-next-line no-unused-vars
      const { data } = await AxiosInstance.put(`category/${id}`,catigoryData)

      return {id , catigoryData}
    } catch (error) {
      return rejectWithValue(error.response ? error.response.data : error )
    }
  }
)

export const deleteCategories = createAsyncThunk('cat/delete',
  async (id , { rejectWithValue })=>{
    try {
      // eslint-disable-next-line no-unused-vars
      const res = await AxiosInstance.delete(`category/${id}`)

      return id
    } catch (error) {
      return rejectWithValue(error.response ? error.response.data : error )
    }
  }
)


const categorySlice = createSlice({
  name:"category",
  initialState:{
    data:[],
    dataOptions:[],
    loading:false,
    error:null
  },
  extraReducers:(builder)=>{
    builder
      // getAll with Out Pagination
      .addCase(getAllCategoriesWithMainName.pending , (state)=>{
        state.loading = true
        state.error = null
      })
      .addCase(getAllCategoriesWithMainName.fulfilled , (state , acthion)=>{
        state.loading = false
        state.error = null
        state.dataOptions = acthion.payload.data
      })
      .addCase(getAllCategoriesWithMainName.rejected , (state , acthion)=>{
        state.loading = false
        state.error = acthion.payload
      })
      // creat Cat
      .addCase(addCategories.pending , (state)=>{
        state.loading = true
        state.error = null
      })
      .addCase(addCategories.fulfilled , (state , acthion)=>{
        state.loading = false
        state.error = null
        state.data.data = [...state.data?.data , acthion.payload?.data] 
      })
      .addCase(addCategories.rejected , (state , acthion)=>{
        state.loading = false
        state.error = acthion.payload
      })
      // get 
      .addCase(getAllCategories.pending , (state)=>{
        state.loading = true
        state.error = null
      })
      .addCase(getAllCategories.fulfilled , (state , acthion)=>{
        state.loading = false
        state.error = null
        state.data = acthion.payload
      })
      .addCase(getAllCategories.rejected , (state , acthion)=>{
        state.loading = false
        state.error = acthion.payload;
        if(acthion.payload.status == 404 ){
          state.data = {data:[]}
        }
      })
      // Update
      // #region
      .addCase(updateCategories.pending , (state)=>{
        state.loading = true
        state.error = null
      })
      .addCase(updateCategories.fulfilled , (state , action)=>{
        state.loading = false
        state.data.data = state.data.data.map((i)=>{
          // eslint-disable-next-line eqeqeq
          if(i._id == action.payload.id){
            return {...i , name:action.payload.catigoryData.name , maincategory:action.payload.catigoryData.maincategory}
          }
          else{
            return i
          }
        })
        state.error = null
      })
      .addCase(updateCategories.rejected , (state , acthion)=>{
        state.loading = false
        state.error = acthion.payload
      })
      // #endregion
      
      // Search
      // #region
      .addCase(searchCategories.pending , (state)=>{
        state.loading = true
        state.error = null
      })
      .addCase(searchCategories.fulfilled , (state , acthion)=>{
        state.loading = false
        state.error = null
        state.data.data = acthion.payload.data
      })
      .addCase(searchCategories.rejected , (state , acthion)=>{
        state.loading = false
        state.error = acthion.payload
      })
      // #endregion
      
      //  delete
      // #region
      .addCase(deleteCategories.pending , (state)=>{
        state.loading = true
      })
      .addCase(deleteCategories.fulfilled , (state , acthion)=>{
        state.loading = false
        state.error = null
        state.data.data = state.data.data.filter((i)=> i._id !== acthion.payload)
      })
      .addCase(deleteCategories.rejected , (state , acthion)=>{
        state.loading = false
        state.error = acthion.payload
      })
      // #endregion
  }
})

export default categorySlice.reducer