import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from 'react-router-dom'
function ProtectedRoute({ children }) {
  const { isAuth } = useSelector( state => state.user )
  const navigation = useNavigate()
  useEffect(()=>{
    if(!isAuth) {
      console.log("isAuth",isAuth)
      return navigation('../login' , {replace:true})
    }
  },[isAuth, navigation])

  return <div>{children}</div>;
}

export default ProtectedRoute;
