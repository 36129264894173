/* eslint-disable eqeqeq */
import React, { useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import Select from "react-select";
import TitleSectionInputs from "./addPackage/TitleSectionInputs";
import IconsSectionInput from "./addPackage/IconsSectionInput";
import {
  MdAddLocationAlt,
  MdFamilyRestroom,
  MdLeakRemove,
  MdLocalLaundryService,
  MdOutlineCalendarMonth,
  MdOutlineRoomService,
  MdPool,
  MdRestaurantMenu,
  MdTitle,
} from "react-icons/md";
import ErrorValidation from "./addPackage/ErrorValidation";
import {
  RiArticleLine,
  RiCarWashingLine,
  RiPriceTag2Line,
} from "react-icons/ri";
import { TbClockHour4, TbPoolOff, TbWashTumbleOff } from "react-icons/tb";
import { useDispatch, useSelector } from "react-redux";
import { getAllCategoriesWithMainName } from "../redux/slicers/category";
import { getAllLocation } from "../redux/slicers/location";
import Button from "./Button.com";
import { CiCircleRemove } from "react-icons/ci";
import { RxShadowNone } from "react-icons/rx";
import { FaCreativeCommonsSampling, FaUmbrellaBeach } from "react-icons/fa";
import { CgGym } from "react-icons/cg";
import { BiWifi, BiWifiOff } from "react-icons/bi";
import { toast } from "react-toastify";
import { updatePackage } from "../redux/slicers/packages";
import { checkJsonIsValid, mainCategory } from "../helper/constants";
import { IoIosImages } from "react-icons/io";
// start editor
import { Editor } from "@tinymce/tinymce-react";
import DOMPurify from "dompurify";

function UpdatePackage({ onClose }) {
  const dispatch = useDispatch();
  const {
    register,
    formState: { errors },
    handleSubmit,
    control,
  } = useForm();
  const { packageData, loading } = useSelector((state) => state.packages);
  const { dataOptions: dataCategory, loading: loadingCategory } = useSelector(
    (state) => state.category
  );

  const { dataOption: dataLocation, isLoading: loadingLocation } = useSelector(
    (state) => state.location
  );

  // start handel category
  const [optionsCatogry, setOptionsCatogry] = useState(null);
  const [selectedMainCat, setSelectedMainCat] = useState({
    value: packageData?.category?.maincategory,
    label: packageData?.category?.maincategory,
  });
  useEffect(() => {
    if (selectedMainCat) {
      dispatch(getAllCategoriesWithMainName(selectedMainCat.value));
    }
  }, [dispatch, selectedMainCat]);
  useEffect(() => {
    if (dataCategory) {
      if (dataCategory.length > 0) {
        setOptionsCatogry(
          dataCategory.map((one) => {
            return { value: one._id, label: one.name };
          })
        );
      } else setOptionsCatogry();
    }
  }, [dataCategory]);

  // start handel location
  const [optionsLocations, setOptionsLocations] = useState(null);
  useEffect(() => {
    if (dataLocation) {
      setOptionsLocations(
        dataLocation.map((one) => {
          return { value: one._id, label: one.name };
        })
      );
    }
  }, [dataLocation]);

  useEffect(() => {
    dispatch(getAllLocation())
      .unwrap()
      .then(() => {
        setSelectedLocation(
          packageData.location.map((i) => {
            return { value: i._id, label: i.name };
          })
        );
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch]);

  // Start Functions Overview
  //#region
  const [selectedLocation, setSelectedLocation] = useState();
  const [selectedCatogry, setSelectedCatogry] = useState({
    value: packageData?.category?._id,
    label: packageData?.category?.name,
  });
  function handleSelectLocation(data) {
    setSelectedLocation(data);
  }
  function handleSelectCatogry(data) {
    setSelectedCatogry(data);
  }
  //#endregion
  // End Functions OverView

  // Start Functions Highlights
  // #region
  const nameMainAddres = "mainAddres";
  const [mainAddressList, setMainAddressList] = useState(() => {
    let parseArray = packageData.highlights.map((i) =>
      checkJsonIsValid(i) ? JSON.parse(i) : i 
    );
    return parseArray.map((i) => {
      return {
        mainAddres: i.mainAddres,
        subAddress: i.subAddress,
      };
    });
  });

  const handleInputChange = (event, index) => {
    const { name: nameTheInput, value } = event;

    if (nameTheInput == nameMainAddres) {
      setMainAddressList((prevArray) => {
        const newArray = [...prevArray];
        newArray[index][nameTheInput] = value;
        return newArray;
      });
    } else {
      setMainAddressList((prevArray) => {
        const newArray = [...prevArray];
        newArray[index].subAddress = value;
        return newArray;
      });
    }
  };

  const handleAddMainAddress = (e) => {
    e.preventDefault();
    setMainAddressList([
      ...mainAddressList,
      {
        mainAddres: "",
        subAddress: "",
      },
    ]);
  };
  // start Editor
  function createMarkup(html) {
    return {
      __html: DOMPurify.sanitize(html),
    };
  }
  const handleRemoveMainAddress = (e, indexMainAddress) => {
    e.preventDefault();

    setMainAddressList((prevArray) => {
      const newArray = [...prevArray];
      newArray.splice(indexMainAddress, 1);
      return newArray;
    });
  };

  // #endregion
  // End Functions Highlights

  // Start Functions Inclusions
  // #region
  const [inclusionsList, setInclusionsList] = useState([
    {
      subInclusions: packageData.inclusions.map((i) => i),
    },
    {
      subInclusions: packageData.exclusions.map((i) => i),
    },
  ]);

  const handleInputInclusionsChange = (event, indexTheSubInclusions, index) => {
    const { value } = event.target;

    setInclusionsList((prevArray) => {
      const newArray = [...prevArray];
      newArray[index].subInclusions[indexTheSubInclusions] = value;
      return newArray;
    });
  };

  const handleAddSubInclusions = (e, index) => {
    e.preventDefault();
    setInclusionsList((prevArray) => {
      const newArray = [...prevArray];
      newArray[index].subInclusions.push("");
      return newArray;
    });
  };

  const handleRemoveSubInclusions = (
    e,
    indexSubInclusions,
    indexMainInclusions
  ) => {
    e.preventDefault();

    if (!indexSubInclusions) {
      console.log("No Delete End Inclusions");
    } else {
      setInclusionsList((prevArray) => {
        const newArray = [...prevArray];
        newArray[indexMainInclusions].subInclusions.splice(
          indexSubInclusions,
          1
        );
        return newArray;
      });
    }
  };
  // #endregion
  // End Functions Inclusions

  // Start Functions Hotels
  // #region
  const [hotelActivities, setHotelActivities] = useState(
    packageData.hotelActivities.map((i) => JSON.parse(i))
  );

  function handleActive(e) {
    e.preventDefault();
    // console.log(hotelActivities)
    let { name, value } = e.target;
    setHotelActivities((prevArray) => {
      const newArray = [...prevArray];
      newArray[+name][value] = !newArray[+name][value];
      return newArray;
    });
  }
  // #endregion
  // End Functions Hotels

  // Start Functions Images
  const [urlImages, setUrlImages] = useState([...packageData?.images]);
  const handleUbloadFiles = (value, index) => {
    setUrlImages((prev) => {
      const newArr = [...prev];
      newArr[index] = value;
      return newArr;
    });
  };

  const onUpdate = (data) => {
    const [Inclusions, Exclusions] = inclusionsList;
    const locationList = selectedLocation.map((one) => {
      return one.value;
    });
    const mainAddress = mainAddressList.map((one) => one);
    const hotels = hotelActivities.map((one) => JSON.stringify(one));

    const sendData = {
      title: data.title,
      descrption: data.discription,
      price: data.price,
      days: data.duration,
      pick_drop: data.pickup,
      availablitiy: data.tourAvaliability,
      category: selectedCatogry.value,
      location: locationList,
      highlights: mainAddress,
      inclusions: Inclusions.subInclusions,
      exclusions: Exclusions.subInclusions,
      hotelActivities: hotels,
      maincategory: selectedMainCat.value,
      images: urlImages,
      typeItenary: data.typeItenary,
    };
    dispatch(updatePackage({ id: packageData._id, packageData: sendData }))
      .unwrap()
      .then((res) => {
        if (res) {
          onClose();
          toast.success("Done Update Package");
        }
      })
      .catch((err) => {
        toast.error(err.message);
      });
  };
  return (
    <>
      <form
        onSubmit={handleSubmit(onUpdate)}
        className="max-w-[80vw] w-[70vw] max-h-[80vh] pt-4 mt-1 px-2"
      >
        {/* Start Overview */}
        <div
          className={`border ${
            errors.title?.message || errors.discription?.message
              ? "border-red-300"
              : "border-green-300"
          }  px-5 pb-1 mb-10 pt-8 rounded relative`}
        >
          <TitleSectionInputs
            message={errors.title?.message || errors.discription?.message}
          >
            Overview
          </TitleSectionInputs>
          <div className="md:flex gap-2 justify-around">
            {/* title */}
            <div className="flex-1">
              <div className="relative max-w-full rounded-l-md">
                <input
                  placeholder="Enter Title"
                  className="p-2 border-2 text-[15px] pl-9 w-full rounded-l-md rounded-r-md focus:outline-none dark:text-black"
                  type="text"
                  defaultValue={packageData?.title}
                  {...register("title")}
                />
                <IconsSectionInput>
                  <MdTitle />
                </IconsSectionInput>
              </div>
              <ErrorValidation message={errors.title?.message} mt={"mt-3"} />
            </div>
            {/* descripthion */}
            <div className="flex-1">
              <div className="relative max-w-full  overflow-hidden rounded-l-md">
                <textarea
                  placeholder="Enter Discription"
                  name="discription"
                  rows="1"
                  cols="50"
                  defaultValue={packageData?.descrption}
                  className="p-2 border-2 max-h-[70px] min-h-[40px] text-[15px] pl-9 w-full rounded-l-md rounded-r-md focus:outline-none dark:text-black"
                  {...register("discription")}
                />
                <IconsSectionInput textarea={"true"}>
                  <RiArticleLine />
                </IconsSectionInput>
              </div>
              <ErrorValidation message={errors.discription?.message} />
            </div>
          </div>

          <div className="md:flex gap-2 justify-around mt-2">
            {/* price */}
            <div className="flex-1">
              <div className="relative max-w-full rounded-l-md">
                <input
                  placeholder="Enter Price"
                  className="p-2 border-2 text-[15px] pl-9 w-full rounded-l-md rounded-r-md focus:outline-none dark:text-black"
                  type="number"
                  defaultValue={packageData.price}
                  {...register("price")}
                />
                <IconsSectionInput>
                  <RiPriceTag2Line />
                </IconsSectionInput>
              </div>
              <ErrorValidation message={errors.price?.message} />
            </div>

            {/* days */}
            <div className="flex-1">
              <div className="relative max-w-full rounded-l-md">
                <input
                  placeholder="Enter Duration"
                  className="p-2 border-2 text-[15px] pl-9 w-full rounded-l-md rounded-r-md focus:outline-none dark:text-black"
                  type="text"
                  defaultValue={packageData?.days}
                  {...register("duration")}
                />
                <IconsSectionInput>
                  <TbClockHour4 />
                </IconsSectionInput>
              </div>
              <ErrorValidation message={errors.duration?.message} />
            </div>
            {/* Category */}
            <div className="flex-1">
              <div className="relative max-w-full rounded-l-md">
                {loadingLocation ? (
                  "Loading..."
                ) : (
                  <Select
                    options={optionsLocations}
                    isMulti
                    required
                    value={selectedLocation}
                    onChange={handleSelectLocation}
                    isSearchable={true}
                    placeholder="Select Location"
                    className=" border-2 text-[15px] rounded-l-md rounded-r-md focus:outline-none dark:text-black"
                  />
                )}
              </div>
            </div>
          </div>

          <div className="md:flex gap-2 justify-around mt-2">
            {/* availablitiy */}
            <div className="flex-1">
              <div className="relative max-w-full rounded-l-md">
                <input
                  placeholder="Enter Tour Avaliability"
                  className="p-2 border-2 text-[15px] pl-9 w-full rounded-l-md rounded-r-md focus:outline-none dark:text-black"
                  type="text"
                  defaultValue={packageData?.availablitiy}
                  {...register("tourAvaliability")}
                />
                <IconsSectionInput>
                  <MdOutlineCalendarMonth />
                </IconsSectionInput>
              </div>
              <ErrorValidation message={errors.tourAvaliability?.message} />
            </div>
            {/* pick_drop */}
            <div className="flex-1">
              <div className="relative max-w-full rounded-l-md">
                <input
                  placeholder="Enter Pick-up & Drope-off"
                  className="p-2 border-2 text-[15px] pl-9 w-full rounded-l-md rounded-r-md focus:outline-none dark:text-black"
                  type="text"
                  defaultValue={packageData?.pick_drop}
                  {...register("pickup")}
                />
                <IconsSectionInput>
                  <RiCarWashingLine />
                </IconsSectionInput>
              </div>
              <ErrorValidation message={errors.pickup?.message} />
            </div>
          </div>

          <div className="md:flex gap-2 justify-around mt-2">
            <div className="flex-1">
              <div className="relative max-w-full rounded-l-md">
                <Select
                  options={mainCategory}
                  required
                  onChange={(e) => {
                    setSelectedCatogry();
                    setSelectedMainCat(e);
                  }}
                  value={selectedMainCat}
                  isSearchable={true}
                  placeholder="Select Main Catogry"
                  className=" border-2 text-[15px] rounded-l-md rounded-r-md focus:outline-none dark:text-black"
                />
              </div>
            </div>

            <div className="flex-1">
              <div className="relative max-w-full rounded-l-md">
                {loadingCategory ? (
                  "Loading..."
                ) : (
                  <Select
                    options={optionsCatogry}
                    // isMulti
                    required
                    value={selectedCatogry}
                    onChange={handleSelectCatogry}
                    isSearchable={true}
                    placeholder="Select Catogry"
                    className=" border-2 text-[15px] rounded-l-md rounded-r-md focus:outline-none dark:text-black"
                  />
                )}
              </div>
            </div>
          </div>
        </div>
        {/* End Overview */}

        {/* Start Highlights */}
        <div
          className={`border ${
            errors.mainAddress?.message || errors.subAddress?.message
              ? "border-red-300"
              : "border-green-300"
          }  px-5 pb-1 mb-10 pt-8 rounded relative`}
        >
          <TitleSectionInputs
            message={errors.mainAddress?.message || errors.subAddress?.message}
          >
            Itenary
          </TitleSectionInputs>
          <div className="flex gap-2">
            <div className="flex-1 justify-around">
              <button
                onClick={(e) => handleAddMainAddress(e)}
                className="bg-blue-500 px-3 py-1 flex m-auto mb-2 rounded border-2 border-blue-500 hover:border-2 hover:bg-blue-400 duration-500 "
              >
                Add New Itenary
              </button>
              {/* type itenary */}
              <div className="w-full my-3 flex justify-center">
                <Controller
                  name="typeItenary"
                  control={control}
                  render={({ field: { onChange, ref } }) => (
                    <Select
                      options={[
                        { value: "normalItenary", label: "Normal Itenary" },
                        { value: "multiItenary", label: "Multi Itenary" },
                      ]}
                      ref={ref}
                      required
                      onChange={(e) => onChange(e.value)}
                      isSearchable={true}
                      defaultValue={packageData?.typeItenary}
                      placeholder="Select Type Itenary"
                      className=" border-2 text-[15px] rounded-l-md w-[80%] rounded-r-md focus:outline-none dark:text-black"
                    />
                  )}
                />
              </div>
              {mainAddressList.map((main, index) => (
                <div
                  key={index}
                  className="mb-8 border-2 p-5 rounded border-blue-300 pb-2"
                >
                  <div className="relative">
                    <p className="absolute border-2 h-6 w-6 flex justify-center items-center border-blue-500 bg-white rounded-full dark:text-black -top-8 -left-8">
                      {index + 1}
                    </p>
                    <button
                      disabled={index < 1}
                      onClick={(e) => handleRemoveMainAddress(e, index)}
                      className="absolute border-2 h-8 w-8 flex justify-center items-center border-red-100 bg-red-100 text-red-600 cursor-pointer hover:bg-red-600 hover:text-red-100 duration-500 rounded-full -top-8 -right-8 "
                    >
                      <CiCircleRemove size={30} />
                    </button>
                    <div className="relative max-w-full rounded-l-md">
                      <input
                        name={nameMainAddres}
                        placeholder="Enter Main Address"
                        className="p-2 border-2 text-[15px] pl-9 w-full rounded-l-md rounded-r-md focus:outline-none dark:text-black"
                        type="text"
                        required
                        value={main.mainAddres}
                        onChange={(e) => handleInputChange(e.target, index)}
                      />
                      <IconsSectionInput>
                        <MdAddLocationAlt />
                      </IconsSectionInput>
                    </div>
                  </div>

                  <div className="flex gap-1 my-1">
                    <div className="flex-1 relative max-w-full overflow-hidden rounded-l-md">
                      <Editor
                        apiKey={
                          "x4jw6slw7ux290c6sxls9ox3hr27rv6gr2p65f46941h00ic"
                        }
                        value={main.subAddress}
                        onEditorChange={(e) =>
                          handleInputChange(
                            { name: "subaddress", value: e },
                            index
                          )
                        }
                        initialValue={main.subAddress}
                      />
                      <div
                        dangerouslySetInnerHTML={createMarkup(main.subAddress)}
                      ></div>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
        {/* End Highlights */}

        {/* Start Inclusions */}
        <div
          className={`border ${
            errors.mainAddress?.message || errors.subAddress?.message
              ? "border-red-300"
              : "border-green-300"
          }  px-5 pb-1 mb-10 pt-14 rounded relative`}
        >
          <TitleSectionInputs
            message={errors.mainAddress?.message || errors.subAddress?.message}
          >
            Inclusions
          </TitleSectionInputs>
          <div className="flex gap-2">
            <div className="flex-1 justify-around">
              {inclusionsList.map((main, index) => (
                <div
                  key={index}
                  className="mb-8 border-2 p-5 rounded border-blue-300 pb-2"
                >
                  <div className="relative">
                    <p className="absolute text-sm border-2 px-2 flex justify-center items-center border-blue-500 bg-white rounded-full dark:text-black -top-8 -left-8">
                      {index == 0 ? "Tour Inclusions" : "Tour Exclusions "}{" "}
                    </p>
                  </div>

                  {main.subInclusions.map((sub, i) => (
                    <div key={i} className="flex gap-1 my-1">
                      <div className="flex-1 relative max-w-full rounded-l-md">
                        <input
                          name={sub}
                          placeholder={`Enter ${
                            index == 0 ? "Tour Inclusions" : "Tour  Exclusions"
                          }`}
                          className="p-2 border-2 text-[15px] pl-9 w-full rounded-l-md rounded-r-md focus:outline-none dark:text-black"
                          type="text"
                          required
                          value={sub}
                          onChange={(e) =>
                            handleInputInclusionsChange(e, i, index)
                          }
                        />
                        <IconsSectionInput>
                          <span className="border border-black h-5 w-5 text-sm flex justify-center items-center rounded-full">
                            {i + 1}
                          </span>
                        </IconsSectionInput>
                      </div>
                      <button
                        disabled={i < 1}
                        onClick={(e) => handleRemoveSubInclusions(e, i, index)}
                        className="g-red-200  rounded px-2 text-red-900 hover:bg-red-700 hover:text-red-100 duration-300"
                      >
                        <CiCircleRemove size={25} />
                      </button>
                    </div>
                  ))}
                  <button
                    onClick={(e) => handleAddSubInclusions(e, index)}
                    className="bg-green-400 w-full py-1 mt-2 rounded hover:bg-green-300 duration-500"
                  >
                    {index == 0
                      ? "Add New Tour Inclusions"
                      : "Add New Tour Exclusions"}
                  </button>
                </div>
              ))}
            </div>
          </div>

          {/* Start Hotel Settings  */}
          <div className="mb-8 border-2 p-5 rounded border-blue-300 pb-2">
            <div className="relative">
              <p className="absolute text-sm border-2 px-2 flex justify-center items-center border-blue-500 bg-white rounded-full dark:text-black -top-8 -left-8">
                Hotel Activatis
              </p>
            </div>

            <div>
              <button
                value="wifi"
                name="0"
                onClick={(e) => handleActive(e)}
                className={`${
                  hotelActivities[0].wifi
                    ? "bg-green-600 hover:bg-green-600"
                    : "bg-black"
                } relative bg-black text-white h-10 w-32 mr-1 ml-6 rounded hover:bg-gray-800 duration-500`}
              >
                <span className="absolute px-2 rounded bg-black border-r-2 border-white text-white flex items-center h-full top-0 -left-5">
                  {hotelActivities[0].wifi ? (
                    <BiWifi size={25} />
                  ) : (
                    <BiWifiOff size={25} />
                  )}
                </span>
                WiFi
              </button>
              <button
                value="pool"
                name="1"
                onClick={(e) => handleActive(e)}
                className={`${
                  hotelActivities[1].pool
                    ? "bg-green-600 hover:bg-green-600"
                    : "bg-black"
                } relative bg-black text-white h-10 w-32 mr-1 ml-6 rounded hover:bg-gray-800 duration-500`}
              >
                <span className="absolute px-2 rounded bg-black border-r-2 border-white text-white flex items-center h-full top-0 -left-5">
                  {hotelActivities[1].pool ? (
                    <MdPool size={25} />
                  ) : (
                    <TbPoolOff size={25} />
                  )}
                </span>
                Pool
              </button>
              <button
                value="laundry"
                name="2"
                onClick={(e) => handleActive(e)}
                className={`${
                  hotelActivities[2].laundry
                    ? "bg-green-600 hover:bg-green-600"
                    : "bg-black"
                } relative bg-black text-white h-10 w-32 mr-1 ml-6 rounded hover:bg-gray-800 duration-500`}
              >
                <span className="absolute px-2 rounded bg-black border-r-2 border-white text-white flex items-center h-full top-0 -left-5">
                  {hotelActivities[2].laundry ? (
                    <MdLocalLaundryService size={25} />
                  ) : (
                    <TbWashTumbleOff size={25} />
                  )}
                </span>
                Laundry
              </button>
              <button
                value="gym"
                name="3"
                onClick={(e) => handleActive(e)}
                className={`${
                  hotelActivities[3].gym
                    ? "bg-green-600 hover:bg-green-600"
                    : "bg-black"
                } relative bg-black text-white h-10 w-32 mr-1 ml-6 rounded hover:bg-gray-800 duration-500`}
              >
                <span className="absolute px-2 rounded bg-black border-r-2 border-white text-white flex items-center h-full top-0 -left-5">
                  {hotelActivities[3].gym ? (
                    <CgGym size={25} />
                  ) : (
                    <MdLeakRemove size={25} />
                  )}
                </span>
                GYM
              </button>
              <button
                value="restaurant"
                name="4"
                onClick={(e) => handleActive(e)}
                className={`${
                  hotelActivities[4].restaurant
                    ? "bg-green-600 hover:bg-green-600"
                    : "bg-black"
                } relative bg-black text-white h-10 w-36 mr-1 ml-6 rounded hover:bg-gray-800 duration-500`}
              >
                <span className="absolute px-2 rounded bg-black border-r-2 border-white text-white flex items-center h-full top-0 -left-5">
                  {hotelActivities[4].restaurant ? (
                    <MdRestaurantMenu size={25} />
                  ) : (
                    <FaCreativeCommonsSampling size={25} />
                  )}
                </span>
                Restaurant
              </button>
              <button
                value="beachfront"
                name="5"
                onClick={(e) => handleActive(e)}
                className={`${
                  hotelActivities[5].beachfront
                    ? "bg-green-600 hover:bg-green-600"
                    : "bg-black"
                } relative bg-black text-white h-10 w-40 mr-1 mt-2 mb-2 ml-6 rounded hover:bg-gray-800 duration-500`}
              >
                <span className="absolute px-2 rounded bg-black border-r-2 border-white text-white flex items-center h-full top-0 -left-5">
                  {hotelActivities[5].beachfront ? (
                    <FaUmbrellaBeach size={25} />
                  ) : (
                    <RxShadowNone size={25} />
                  )}
                </span>
                Beachfront
              </button>
              <button
                value="romService"
                name="6"
                onClick={(e) => handleActive(e)}
                className={`${
                  hotelActivities[6].romService
                    ? "bg-green-600 hover:bg-green-600"
                    : "bg-black"
                } relative bg-black text-white h-10 w-40 mr-1 ml-6 rounded hover:bg-gray-800 duration-500`}
              >
                <span className="absolute px-2 rounded bg-black border-r-2 border-white text-white flex items-center h-full top-0 -left-5">
                  {hotelActivities[6].romService ? (
                    <MdOutlineRoomService size={25} />
                  ) : (
                    <RxShadowNone size={25} />
                  )}
                </span>
                Rom Service
              </button>
              <button
                value="familyRooms"
                name="7"
                onClick={(e) => handleActive(e)}
                className={`${
                  hotelActivities[7].familyRooms
                    ? "bg-green-600 hover:bg-green-600"
                    : "bg-black"
                } relative bg-black text-white h-10 w-44 mr-1 ml-6 rounded hover:bg-gray-800 duration-500`}
              >
                <span className="absolute px-2 rounded bg-black border-r-2 border-white text-white flex items-center h-full top-0 -left-5">
                  {hotelActivities[7].familyRooms ? (
                    <MdFamilyRestroom size={25} />
                  ) : (
                    <RxShadowNone size={25} />
                  )}
                </span>
                Family Rooms
              </button>
            </div>
          </div>
          {/* End Hotel Settings  */}
        </div>

        {/* Start Images */}
        <div
          className={`border ${
            errors.mainAddress?.message || errors.subAddress?.message
              ? "border-red-300"
              : "border-green-300"
          }  px-5 pb-1 mb-10 pt-14 rounded relative`}
        >
          <TitleSectionInputs
            message={errors.mainAddress?.message || errors.subAddress?.message}
          >
            Images
          </TitleSectionInputs>

          <div>
            {urlImages.map((_, index) => (
              <div key={index} className="relative mb-3">
                <input
                  className="p-2 border-2 text-[15px] pl-9 w-full rounded-l-md rounded-r-md focus:outline-none dark:text-black"
                  type="url"
                  placeholder={`Enter Url Image ${index + 1}`}
                  name="images"
                  onChange={(e) => handleUbloadFiles(e.target.value, index)}
                  required
                  defaultValue={_}
                  pattern="^https?:\/\/res\.cloudinary\.com.*"
                />
                <IconsSectionInput>
                  <IoIosImages />
                </IconsSectionInput>
              </div>
            ))}
          </div>
        </div>

        {/* End Inclusions */}
        <div className="w-full flex justify-center">
          <Button disabled={loading} type={"submit"} value={"Update Package"} />
        </div>
      </form>
    </>
  );
}

export default UpdatePackage;
