/* eslint-disable react-hooks/exhaustive-deps */
import { Route, Routes, 
    useLocation, useNavigate 
  } from "react-router-dom";
import Home from "./pages/Home.page";
import RooyLayOut from "./pages/RootLayOut";
import Category from "./pages/Category";
import AllPackages from "./pages/AllPackages";
import AddPackage from "./pages/AddPackage";
import "react-confirm-alert/src/react-confirm-alert.css"; // Import css
import Login from "./pages/Login";
import "react-toastify/dist/ReactToastify.css";
import CustomePackages from "./pages/CustomePackages.page";
import { useDispatch } from "react-redux";
import { useEffect } from "react";
import ContactUs from "./pages/ContactUs";
import DetailsPackage from "./pages/DetailsPackage";
import { userData } from "./helper/constants";
import { getUser, setIsAuth } from "./redux/slicers/user";
import Protected from './components/ProtectedRoute'
import './components/animathion.css'
import AllTrips from "./pages/AllTrips";
import Location from "./pages/Location";
import AllBlogs from "./pages/AllBlogs";
import AddBlog from "./pages/AddBlog";
import BlogDetails from "./pages/BlogDetails";
function App() {
  const dispatch = useDispatch()
  const location = useLocation()
  const navigathion = useNavigate()

  useEffect(() => {
    if (userData && typeof userData === "object") {
      if (
        userData.statusCode === 200 &&
        userData.message === "the user is aouthrized" &&
        userData.id &&
        userData.token
      ) {
        dispatch(getUser(userData.id))
          .unwrap()
          .then((res) => {
            if (res.statusCode === 200) dispatch(setIsAuth(true))
          })
          .catch(() => {
            dispatch(setIsAuth(false))
            if(location.pathname !== '/login'){
              navigathion('../login',{replace:true})
            }
          })
      }
      else {
        dispatch(setIsAuth(false))
        if(location.pathname !== '/login'){
          navigathion('../login',{replace:true})
        }
      }
    }
    else {
      dispatch(setIsAuth(false))
      if(location.pathname !== '/login'){
        navigathion('../login',{replace:true})
      }
    }
  }, [dispatch]);
  return (
      <Routes>
        <Route index path="login" element={<Login />} />
        {<Route path="/" element={<RooyLayOut />}>
          <Route index element={<Protected > <Home /> </Protected>} />
          <Route path="allPackages" element={<Protected > <AllPackages /> </Protected>} /> 
          <Route path="allPackages/:id" element={<Protected > <DetailsPackage /> </Protected>} /> 
          <Route path="addPackage" element={<Protected >  <AddPackage /> </Protected> } />
          <Route path="customePackages" element={<Protected >  <CustomePackages /> </Protected>} />
          <Route path="allblogs" element={<Protected >  <AllBlogs /> </Protected>} />
          <Route path="allblogs/:id" element={<Protected >  <BlogDetails /> </Protected>} />
          <Route path="Addblog" element={<Protected >  <AddBlog /> </Protected>} />
          <Route path="trips" element={<Protected >  <AllTrips /> </Protected>} />
          <Route path="category" element={<Protected > <Category /> </Protected>} />
          <Route path="location" element={<Protected > <Location /> </Protected>} />
          <Route path="contactUs" element={<Protected >  <ContactUs /> </Protected>} />
        </Route>}
      </Routes>
  );
}

export default App;
