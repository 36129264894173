import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import AxiosInstance from "../AxiosInstance";

export const getCustomePackage = createAsyncThunk("customePackage/get",
  async ( {pageNumper , signal} , { rejectWithValue })=>{
    try {
      const { data } = await AxiosInstance.get(`trips/${pageNumper}`,{signal: signal})

      return data
    } catch (error) {
      return rejectWithValue(error.response ? error.response.data : error )
    }
  }
)

export const deleteCustomePackage = createAsyncThunk("customePackage/delete",
  async ( id, { rejectWithValue })=>{
    try {
      // eslint-disable-next-line no-unused-vars
      const data = await AxiosInstance.delete(`trips/${id}`)
      
      return id
    } catch (error) {
      return rejectWithValue(error.response ? error.response.data : error )
    }
  }
)


const packageSlice = createSlice({
  name:'customPackage',
  initialState:{
    data:[],
    loading:false,
    error:null,
    detailsCustomPackage:{}
  },
  reducers:{
    setDetailsCustomPackage:(state , acthion)=>{
      state.detailsCustomPackage = acthion.payload
    }
  },
  extraReducers:(builder)=>{
    builder
      .addCase(getCustomePackage.pending , (state)=>{
        state.loading = true
        state.error = null
      })
      .addCase(getCustomePackage.fulfilled , (state , acthion)=>{
        state.loading = false
        state.error = null
        state.data = acthion.payload ? acthion.payload : []
      })
      .addCase(getCustomePackage.rejected , (state , acthion)=>{
        state.loading = false
        state.error = acthion.payload
      })
      // delete
      .addCase(deleteCustomePackage.pending , (state)=>{
        state.loading = true
        state.error = null
      })
      .addCase(deleteCustomePackage.fulfilled , (state , acthion)=>{
        state.loading = false
        state.error = null
        state.data.data = state.data.data.filter((e) => e._id !== acthion.payload)
      })
      .addCase(deleteCustomePackage.rejected , (state , acthion)=>{
        state.loading = false
        state.error = acthion.payload
      })
  }
})

export const { setDetailsCustomPackage } = packageSlice.actions
export default packageSlice.reducer