import React from "react";

function TableHead({ children, titleArrary = [] , widthCeilOne}) {
  return (
    <div className="max-w-full overflow-auto">
      <table className="w-full text-left text-sm mt-4 shadow-md border-separate border-spacing-x-[1px]">
        <thead className="font-semibold capitalize bg-main text-white text-[16px] tracking-wider">
          <tr className="py-2 md:px-1 lg:px-1 xl:px-1">
            {titleArrary.map((title, index) => (
              <th
                key={index}
                title={title}
                className={`py-3 pl-2 whitespace-nowrap truncate ${
                  !index ? "pl-2 md:w-[5%] lg:w-[5%] xl:w-[5%]" : ""
                } 
                ${title === "opthion" ? "w-[15%]" : ""}
                              ${index === 1 ? ! widthCeilOne ? " max-w-[25%]" : widthCeilOne : ""}`}
                scope="col"
              >
                {title}
              </th>
            ))}
          </tr>
        </thead>
        <tbody>{children}</tbody>
      </table>
    </div>
  );
}

export default React.memo(TableHead);
