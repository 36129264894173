import React from "react";
import { MdOutlineDelete, MdNotificationsActive } from "react-icons/md";
import { BiDetail } from "react-icons/bi";
import { RxUpdate } from "react-icons/rx";
import { FaUsers } from "react-icons/fa";

function TableData({
  number,
  one,
  two,
  three,
  four,
  five,
  six,
  seven,
  eight,
  detailsBtn,
  showBtnDetails,
  deleteBtn,
  children,
  showBtnUpdate,
  onUpdate,
  showBtnUser,
  onUser,
  showBtnRemove,
  showOption = true,
  showBtnNotification,
  onNotification,
  mailLoading,
}) {
  return (
    <>
      <tr className="border hover:bg-main-light transition-all duration-300 dark:hover:bg-black">
        {number && <td className="py-4 px-4 font-semibold">{number}</td>}
        {one && (
          <td title={one} className="py-4 px-4 max-w-[50ch] ">
            {one}
          </td>
        )}
        {two && (
          <td title={two} className="py-4 px-4 max-w-[50ch] ">
            {two}
          </td>
        )}
        {three && (
          <td title={three} className="py-4 px-4 max-w-[50ch] ">
            {three}
          </td>
        )}
        {four && (
          <td title={four} className="py-4 px-4 max-w-[50ch] ">
            {four}
          </td>
        )}
        {five && (
          <td title={five} className="py-4 px-4 max-w-[50ch] ">
            {five}
          </td>
        )}
        {six && (
          <td title={six} className="py-4 px-4 max-w-[50ch] ">
            {six}
          </td>
        )}
        {seven && (
          <td title={seven} className="py-4 px-4 max-w-[50ch] ">
            {seven}
          </td>
        )}
        {eight && (
          <td title={eight} className="py-4 px-4 max-w-[50ch] ">
            {eight}
          </td>
        )}
        {showOption && (
          <td className="py-4 px-4 text-center flex justify-center items-center h-full my-auto min-h-[50px] gap-x-1">
            {showBtnNotification && (
              <button
                title="Send Notification"
                onClick={onNotification}
                disabled={mailLoading}
              >
                {
                  <MdNotificationsActive
                    size={20}
                    className="border-2 py-1 w-[33px] h-[33px] px-1 text-center font-bold text-black capitalize rounded-md ml-1
                hover:bg-transparent bg-white dark:hover:text-white transition-all duration-300
              "
                  />
                }
              </button>
            )}

            {showBtnUpdate && (
              <button title="Update" onClick={onUpdate}>
                <RxUpdate
                  size={20}
                  className="border-2 w-[33px] h-[33px] py-1 px-1 text-center font-bold dark:text-white capitalize rounded-md p-1
              bg-transparent hover:rotate-180 transition-all duration-300
            "
                />
              </button>
            )}
            {showBtnDetails && (
              <button title="Details" onClick={detailsBtn}>
                <BiDetail
                  size={20}
                  className="border-2 w-[33px] h-[33px] py-1 px-1 text-center font-bold text-white capitalize rounded-md
              bg-success hover:bg-transparent hover:border-success hover:text-success transition-all duration-300
            "
                />
              </button>
            )}
            {showBtnUser && (
              <button title="Show Users" onClick={onUser}>
                <FaUsers
                  size={20}
                  className="border-2 py-1 w-[33px] h-[33px] px-1 text-center font-bold text-black capitalize rounded-md ml-1
                bg-main-light hover:bg-white hover:border-white transition-all duration-300
              "
                />
              </button>
            )}
            {showBtnRemove && (
              <button title="Remove" onClick={deleteBtn}>
                <MdOutlineDelete
                  size={30}
                  className="border-2 py-1 w-[33px] h-[33px] px-1 text-center font-bold text-white capitalize rounded-md ml-1
                bg-error hover:bg-transparent hover:border-error hover:border-2 hover:text-error transition-all duration-300
              "
                />
              </button>
            )}
          </td>
        )}
      </tr>

      {children}
    </>
  );
}

export default TableData;
