/* eslint-disable eqeqeq */
import React, { useEffect, useMemo, useState } from "react";

function Pagination({ totalPageNumper, setPageNumper, pageNumper }) {
  const [numper, setNumper] = useState();
  const [arrayLoop, setArrayLoop] = useState([]);

  useMemo(() => {
    setNumper(totalPageNumper >= 5 ? [1, 2, 3, 4, 5] : totalPageNumper);
  }, [totalPageNumper]);

  useEffect(() => {
    //the useEffect must rerender the component because give the last 5 elem of numper array
    if (typeof numper == "object") {
      let newElem = numper.slice(-5);
      setArrayLoop(newElem);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [numper]);
  const type = typeof numper === "object"
  const paginateNumpers = Array.from(
    //totalPageNumper < 5 should loops py totalPageNumper / else should loops py custom Array
    type ? arrayLoop : { length: numper },
    (elem, index) => {
      let num = elem ? elem : +index + 1; // if loops to arrayloop get the elem / else get the index of numper
      return (
        <li key={index}>
          <button
            onClick={() => {
              const lastElem = type && numper.findLast((n) => n);
              console.log(lastElem)
              if (
                type &&
                lastElem < totalPageNumper
              ) {
                if (num >= 5) {
                  setNumper((prev) => [...prev, num + 1]);
                  setPageNumper(num);
                }
              }
              if (
                type &&
                lastElem > 5 &&
                numper.slice(-5)[0] == num 
              ) {
                setNumper((prev) => prev.filter((e) => e !== lastElem));
                setPageNumper(num);
              } else setPageNumper(num);
            }}
            disabled={pageNumper == num}
            className={`px-3 py-2 leading-tight border border-gray-300 hover:bg-main-light hover:text-gray-700 
            ${
              pageNumper == num
                ? "bg-main-50 text-white"
                : "bg-white text-gray-500"
            }`}
          >
            {num}
          </button>
        </li>
      );
    }
  );

  return (
    <div className="w-full mt-6 text-center">
      <ul className="inline-flex -space-x-px">
        <li>
          <button
            onClick={() => {
              const pop = type && numper.findLast((n) => n);
              if (
                type &&
                pop >= 5 &&
                pageNumper == numper.slice(-5)[0]
              ) {
                setNumper((prev) => prev.filter((e) => e !== pop));
                setPageNumper((prev) => prev - 1);
              } else setPageNumper((prev) => prev - 1);
            }}
            disabled={pageNumper == 1}
            className={`px-3 py-2 ml-0 leading-tight border border-gray-300 rounded-l-lg 
                ${
                  pageNumper == 1
                    ? "bg-[#d3d3d3] text-[#838383]"
                    : "bg-white text-gray-500 hover:bg-main-light hover:text-gray-700"
                }`}
          >
            Previous
          </button>
        </li>

        {paginateNumpers}

        <li>
          <button
            onClick={() => {
              const lastElem = type && numper.findLast((n) => n);
              if (
                type &&
                numper.unshift() < totalPageNumper &&
                pageNumper >= 5 &&
                lastElem == pageNumper
              ) {
                setPageNumper((prev) => prev + 1);
                setNumper((prev) => [...prev, pageNumper + 1]);
              } else {
                setPageNumper((prev) => prev + 1);
              }
            }}
            disabled={pageNumper == totalPageNumper}
            className={`px-3 py-2 ml-0 leading-tight border border-gray-300 rounded-r-lg 
                ${
                  pageNumper == totalPageNumper
                    ? "bg-[#d3d3d3] text-[#838383]"
                    : "bg-white text-gray-500 hover:bg-main-light hover:text-gray-700"
                }`}
          >
            Next
          </button>
        </li>
      </ul>
    </div>
  );
}

export default React.memo(Pagination);
