import React from "react";

function Button({type , value , disabled , className }) {
  return (
      <button
        disabled={disabled}
        className={`bg-main py-1 mb-4 px-5 ${className} cursor-pointer rounded text-white text-center
          hover:shadow-md hover:shadow-slate-500 hover:transition-all hover:duration-200 hover:scale-[1.03]
          active:translate-y-1  active:translate-x-0 active:scale-100 whitespace-nowrap
        `}
        type={type}
      >
        {value}
      </button>
  );
}

export default React.memo(Button);
