import React, { useEffect, useState } from "react";
import Search from "../components/Search.com";
import TableData from "../components/TableData";
import TableHead from "../components/TableHead";
import { TbPackages } from "react-icons/tb";
import ModalCom from "../components/Modal.com";
import CustomePackageDetails from "../components/CustomePackageDetails";
import Pagination from "../components/Pagination";
import { useDispatch, useSelector } from "react-redux";
import {
  deleteCustomePackage,
  getCustomePackage,
  setDetailsCustomPackage,
} from "../redux/slicers/customePackages";
import { confirmAlert } from "react-confirm-alert";
import opthionsAlert from "../helper/opthionConfirmAlert";
import { toast } from "react-toastify";
import Loading from "../components/Loading";
import Error from "../components/Error.com";

function CustomePackages() {
  const dispatch = useDispatch();
  const { data, loading, error, detailsCustomPackage } = useSelector(
    (state) => state.customPackage
  );
  const [pageNumper, setPageNumper] = useState(1);

  useEffect(() => {
    const controler = new AbortController();
    dispatch(getCustomePackage({ pageNumper, signal: controler.signal }));
    return () => {
      controler.abort();
    };
  }, [dispatch, pageNumper]);

  const [modalDetails, setModalDetails] = useState(false);
  const handelShowDetailsModal = (item) => setModalDetails((prev) => !prev);
  const handelDetailsData = (item) => {
    dispatch(setDetailsCustomPackage(item));
    handelShowDetailsModal();
  };

  const handelDeletePackage = (id) => {
    confirmAlert(
      opthionsAlert("Delete", "Are You Delete This Pakege", () => {
        dispatch(deleteCustomePackage(id))
          .unwrap()
          .then((res) => toast.success("Done Delete Custom Trip"))
          .catch((err) => toast.error(err.message));
      })
    );
  };
  return (
    <div className="fadeIn p-5">
      <>
        {/* Start Title  */}
        <div className="flex items-center">
          <span>
            <TbPackages size={35} className="text-main" />
          </span>
          <span className="px-3 font-medium text-2xl">Custome Packages</span>
        </div>

        {/* Start Serch And Filter  */}
        <div className="sm:flex-wrap sm:justify-center sm:flex md:justify-between pt-5">
          <Search placeholder={"Enter Name User"} />
        </div>
        {error && !loading && <Error message={error.message} />}
        {loading && <Loading />}
        {/* Start Table  */}
        {!loading && !error && (
          <>
            <TableHead
              titleArrary={[
                "N",
                "Name",
                "email",
                "Destination",
                "Check In",
                "Cheack Out",
                "Option",
              ]}
            >
              {!loading &&
                data.data &&
                data.data.length !== 0 &&
                data.data.map((item, index) => (
                  <TableData
                    number={index + 1}
                    one={item.name}
                    two={item.emailAddress}
                    three={item.distination}
                    four={item.checkIn}
                    five={item.checkOut}
                    key={item._id}
                    detailsBtn={() => handelDetailsData(item)}
                    showBtnRemove={true}
                    deleteBtn={() => handelDeletePackage(item._id)}
                    showBtnDetails={true}
                  />
                ))}
            </TableHead>
          </>
        )}
        {!error && (
          <Pagination
            totalPageNumper={data.numOfPage}
            setPageNumper={setPageNumper}
            pageNumper={pageNumper}
          />
        )}
      </>

      <ModalCom
        onClose={handelShowDetailsModal}
        visible={modalDetails}
        icon={<TbPackages size={25} className="text-main" />}
        title="Details Package"
        closeOnBackDroupPress={true}
      >
        <CustomePackageDetails data={detailsCustomPackage} />
      </ModalCom>
    </div>
  );
}

export default CustomePackages;
