import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import AxiosInstance from "../AxiosInstance";

export const getBlogs = createAsyncThunk(
  "blog/all",
  async ({ pageNumber, signal }, { rejectWithValue }) => {
    try {
      const { data } = await AxiosInstance.get(`blog/${pageNumber}`, {
        signal,
      });
      return data;
    } catch (error) {
      console.log(error)
      return rejectWithValue(error.response ? error.response.data : error);
    }
  }
);

export const addBlog = createAsyncThunk(
  "blog/add",
  async ( blogData , { rejectWithValue }) => {
    try {
      const { data } = await AxiosInstance.post(`blog/create`, blogData);
      console.log(data)
      return data;
    } catch (error) {
      console.log(error)
      return rejectWithValue(error.response ? error.response.data : error);
    }
  }
);

export const updateBlog = createAsyncThunk(
  "blog/update",
  async ( {id , blogData} , { rejectWithValue , dispatch }) => {
    try {
      // eslint-disable-next-line no-unused-vars
      const { data } = await AxiosInstance.put(`blog/${id}`, blogData);
      
      return blogData;
    } catch (error) {
      console.log(error)
      return rejectWithValue(error.response ? error.response.data : error);
    }
  }
);

export const deleteBlog = createAsyncThunk(
  "blog/remove",
  async ( id , { rejectWithValue }) => {
    try {
      // eslint-disable-next-line no-unused-vars
      const { data } = await AxiosInstance.delete(`blog/${id}`);

      return id;
    } catch (error) {
      return rejectWithValue(error.response ? error.response.data : error);
    }
  }
);

const blogSlice = createSlice({
  name: "blog",
  initialState: {
    data: {},
    blogData: null,
    error: null,
    loading: false,
    loadingAdd:false
  },
  reducers:{
    setBlogData:(state , action)=>{
      state.blogData = action.payload
    }
  },
  extraReducers: (builder) => {
    builder
    // getAll
    // #region
      .addCase(getBlogs.pending, (state) => {
        state.loading = true;
      })
      .addCase(getBlogs.fulfilled, (state, action) => {
        state.loading = false;
        state.data = action.payload;
        state.error = null;
      })
      .addCase(getBlogs.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
    // #endregion
    
    // Add Blog
    // #region
      .addCase(addBlog.pending, (state) => {
        state.loadingAdd = true;
      })
      .addCase(addBlog.fulfilled, (state, action) => {
        state.loadingAdd = false;
        state.error = null;
      })
      .addCase(addBlog.rejected, (state, action) => {
        state.loadingAdd = false;
        state.error = action.payload;
      })
    // #endregion
    
    // Update Blog
    // #region
      .addCase(updateBlog.pending, (state) => {
        state.loadingAdd = true;
      })
      .addCase(updateBlog.fulfilled, (state, action) => {
        state.loadingAdd = false;
        state.error = null;
        state.blogData = action.payload
      })
      .addCase(updateBlog.rejected, (state, action) => {
        state.loadingAdd = false;
        state.error = action.payload;
      })
    // #endregion
    
    // delete
    // #region
      .addCase(deleteBlog.pending, (state) => {
        state.loading = true;
      })
      .addCase(deleteBlog.fulfilled, (state, action) => {
        state.loading = false;
        state.data.data = state.data.data.filter((i)=> i._id !== action.payload)
      })
      .addCase(deleteBlog.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      });
    // #endregion
  },
});

export const { setBlogData } = blogSlice.actions
export default blogSlice.reducer