import React from "react";

function ModalCom({
  visible,
  onClose,
  title,
  icon,
  children,
  closeOnBackDroupPress,
}) {
  if (!visible) return null;

  return (
    <div className="fadeIn fixed top-0 inset-0 z-[60] flex justify-center items-center">
      {/* BackDroup */}
      <div
        onClick={() => (closeOnBackDroupPress ? onClose() : null)}
        className="absolute top-0 left-0 z-50 w-full h-full bg-black bg-opacity-30 backdrop-opacity-70 backdrop-blur-sm"
      ></div>
      {/* Content */}
      <div className="bg-white dark:bg-dark rounded max-w-[92%] min-w-[90%] md:min-w-[80%] lg:min-w-[50%] xl:min-w-[50%] w-max z-[55]">
        {/* Header Modal */}
        <div className="flex justify-between p-4 border-b-2">
          <span className="flex items-center">
            {/* icon */}
            {icon && (
              <span className="border-main border-2 mr-2 rounded-lg">
                {icon}
              </span>
            )}
            <span className="font-medium text-sm">{title}</span>
          </span>
          <span onClick={onClose}>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth={1.5}
              stroke="currentColor"
              className="w-6 h-6 cursor-pointer text-gray-400 hover:rotate-180 duration-500"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M6 18L18 6M6 6l12 12"
              />
            </svg>
          </span>
        </div>
        {/* Body Modal  */}
        <div className="p-2 max-h-[75vh] overflow-auto">
          {children}
        </div>
      </div>
    </div>
  );
}

export default React.memo(ModalCom);
