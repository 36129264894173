import React, { useCallback, useEffect, useState } from "react";
import TableData from "../components/TableData";
import TableHead from "../components/TableHead";
import { useDispatch, useSelector } from "react-redux";
import Pagination from "../components/Pagination";
import Loading from "../components/Loading";
import Error from "../components/Error.com";
import { ToastContainer, toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import { RiArticleFill } from "react-icons/ri";
import { deleteBlog, getBlogs, setBlogData } from "../redux/slicers/blog";
import opthionsAlert from "../helper/opthionConfirmAlert";
import { confirmAlert } from "react-confirm-alert";

function AllBlogs() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { data, loading, error } = useSelector((state) => state.blogs);
  const [pageNumber, setPageNumper] = useState(1);

  useEffect(() => {
    const controler = new AbortController();
    dispatch(getBlogs({ pageNumber, signal: controler.signal }))

    return () => {
      controler.abort();
    };
  }, [dispatch, pageNumber]);


  const handleNavigateToDetailsOneBlog = (id , data) => {
    dispatch(setBlogData(data))
    navigate(`${id}`);
  };

  const handelDeleteBlog = useCallback(
    (id) => {
      confirmAlert(
        opthionsAlert("Delete", "Are You Delete This Blog", () => {
          dispatch(deleteBlog(id))
            .unwrap()
            .then((res) => {
              toast.success("Done Delete Blog");
            })
            .catch((err) => toast.error(err.message));
        })
      );
    },
    [dispatch]
  );

  return (
    <div className="fadeIn p-5">
      <ToastContainer
        position="top-center"
        autoClose={2000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
      />
      {/* Start Title  */}
      <div className="flex items-center">
        <span>
          <RiArticleFill size={35} className="text-main" />
        </span>
        <span className="px-3 font-medium text-2xl">All Blogs</span>
      </div>

      {error && !loading && <Error message={error.message.message ? error.message.message : error.message } />}
      {loading && <Loading />}
      {/* Start Table  */}
      {!loading && !error && data.data && (
        <>
          <TableHead
            titleArrary={[
              "N",
              "title",
              "discription",
              "option",
            ]}
          >
            {data.data.map((item, index) => (
              <TableData
                number={index + 1}
                one={item.title ? item.title : " "}
                two={item.discription ? item.discription.slice(1,50) + '...' : ' ' }
                key={item._id}
                showBtnDetails={true}
                detailsBtn={() => handleNavigateToDetailsOneBlog(item._id , item) }
                showBtnRemove={true}
                deleteBtn={() => handelDeleteBlog(item._id)}
              />
            ))}
          </TableHead>
        </>
      )}
      {!error && <Pagination
        totalPageNumper={data.numOfPage}
        pageNumper={pageNumber}
        setPageNumper={setPageNumper}
      />}
    </div>
  );
}

export default AllBlogs;
