/* eslint-disable eqeqeq */
import React, { useEffect, useState } from "react";
import Conatiner from "../components/Conatiner";
import { Controller, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import Select from "react-select";
import { useDispatch, useSelector } from "react-redux";
import { ToastContainer, toast } from "react-toastify";
// Icons
import { RiArticleLine, RiPriceTag2Line } from "react-icons/ri";
import {
  MdAddLocationAlt,
  MdFamilyRestroom,
  MdLeakRemove,
  MdLocalLaundryService,
  MdOutlineRoomService,
  MdPool,
  MdTitle,
} from "react-icons/md";
import { TbClockHour4, TbPoolOff, TbWashTumbleOff } from "react-icons/tb";
import { CiCircleRemove } from "react-icons/ci";
import { MdOutlineCalendarMonth } from "react-icons/md";
import { RiCarWashingLine } from "react-icons/ri";
import { schemaAddPackage } from "../schemas/schemaAddPackage";
import { CgGym } from "react-icons/cg";
import { MdRestaurantMenu } from "react-icons/md";
import { FaCreativeCommonsSampling, FaUmbrellaBeach } from "react-icons/fa";
import { RxShadowNone } from "react-icons/rx";
import { IoIosImages } from "react-icons/io";
import { BiWifi, BiWifiOff } from "react-icons/bi";
// Functions Redux
import { getAllCategoriesWithMainName } from "../redux/slicers/category";
import { getAllLocation } from "../redux/slicers/location";
import { addPackages } from "../redux/slicers/packages";
// Components
import TitleSectionInputs from "../components/addPackage/TitleSectionInputs";
import IconsSectionInput from "../components/addPackage/IconsSectionInput";
import ErrorValidation from "../components/addPackage/ErrorValidation";
// Style
import "./AddPackage.css";
import Button from "../components/Button.com";
import { Oval } from "react-loader-spinner";
import { useNavigate } from "react-router-dom";
import { createMarkup, mainCategory } from "../helper/constants";

// start editor
import { Editor } from "@tinymce/tinymce-react";

function AddPackage() {
  const navigation = useNavigate();
  const dispatch = useDispatch();
  const { loadingAdd } = useSelector((state) => state.packages);

  const { dataOptions: dataCategory, loading: loadingCategory } = useSelector(
    (state) => state.category
  );
  const [optionsCatogry, setOptionsCatogry] = useState(null);

  const { dataOption: dataLocation, isLoading: loadingLocation } = useSelector(
    (state) => state.location
  );

  const [selectedMainCat, setSelectedMainCat] = useState(null);
  useEffect(() => {
    if (selectedMainCat) {
      dispatch(getAllCategoriesWithMainName(selectedMainCat));
    }
  }, [dispatch, selectedMainCat]);
  useEffect(() => {
    if (dataCategory) {
      setOptionsCatogry(
        dataCategory.map((one) => {
          return { value: one._id, label: one.name };
        })
      );
    }
  }, [dataCategory]);

  const [optionsLocations, setOptionsLocations] = useState(null);
  useEffect(() => {
    if (dataLocation) {
      setOptionsLocations(
        dataLocation.map((one) => {
          return { value: one._id, label: one.name };
        })
      );
    }
  }, [dataLocation]);

  useEffect(() => {
    dispatch(getAllLocation());
  }, [dispatch]);

  // Start Functions Overview
  const [selectedLocation, setSelectedLocation] = useState();
  const [selectedCatogry, setSelectedCatogry] = useState();

  function handleSelectLocation(data) {
    setSelectedLocation(data);
  }

  function handleSelectCatogry(data) {
    setSelectedCatogry(data);
  }

  // End Functions Overview

  // Start Functions Highlights
  const nameMainAddres = "mainAddres";
  const [mainAddressList, setMainAddressList] = useState([
    {
      mainAddres: "",
      subAddress: "",
    },
  ]);

  const handleInputChange = (event, index) => {
    const { name: nameTheInput, value } = event;

    if (nameTheInput == nameMainAddres) {
      setMainAddressList((prevArray) => {
        const newArray = [...prevArray];
        newArray[index][nameTheInput] = value;
        return newArray;
      });
    } else {
      setMainAddressList((prevArray) => {
        const newArray = [...prevArray];
        newArray[index].subAddress = value;
        return newArray;
      });
    }
  };

  const handleAddMainAddress = (e) => {
    e.preventDefault();
    setMainAddressList([
      ...mainAddressList,
      {
        mainAddres: "",
        subAddress: "",
      },
    ]);
  };

  const handleRemoveMainAddress = (e, indexMainAddress) => {
    e.preventDefault();

    setMainAddressList((prevArray) => {
      const newArray = [...prevArray];
      newArray.splice(indexMainAddress, 1);
      return newArray;
    });
  };

  // End Functions Highlights

  // Start Functions Inclusions
  const [inclusionsList, setInclusionsList] = useState([
    {
      subInclusions: ["", ""],
    },
    {
      subInclusions: ["", ""],
    },
  ]);

  const handleInputInclusionsChange = (event, indexTheSubInclusions, index) => {
    const { value } = event.target;

    setInclusionsList((prevArray) => {
      const newArray = [...prevArray];
      newArray[index].subInclusions[indexTheSubInclusions] = value;
      return newArray;
    });
  };

  const handleAddSubInclusions = (e, index) => {
    e.preventDefault();
    setInclusionsList((prevArray) => {
      const newArray = [...prevArray];
      newArray[index].subInclusions.push("");
      return newArray;
    });
  };

  const handleRemoveSubInclusions = (
    e,
    indexSubInclusions,
    indexMainInclusions
  ) => {
    e.preventDefault();

    if (!indexSubInclusions) {
    } else {
      setInclusionsList((prevArray) => {
        const newArray = [...prevArray];
        newArray[indexMainInclusions].subInclusions.splice(
          indexSubInclusions,
          1
        );
        return newArray;
      });
    }
  };
  // End Functions Inclusions

  // Start Functions Hotels
  const [hotelActivities, setHotelActivities] = useState([
    { wifi: false },
    { pool: false },
    { laundry: false },
    { gym: false },
    { restaurant: false },
    { beachfront: false },
    { romService: false },
    { familyRooms: false },
  ]);

  const handleActive = (e) => {
    e.preventDefault();
    const { name, value } = e.target;
    if (name && value) {
      setHotelActivities((prevArray) => {
        const newArray = [...prevArray];
        newArray[+name][value] = !newArray[+name][value];
        return newArray;
      });
    }
  };
  // End Functions Hotels

  // Start Functions Images
  const [urlImages, setUrlImages] = useState([" ", " ", " ", " ", " "]);
  const handleUbloadFiles = (value, index) => {
    setUrlImages((prev) => {
      const newArr = [...prev];
      newArr[index] = value;
      return newArr;
    });
  };

  const {
    register,
    handleSubmit,
    formState: { errors },
    control,
  } = useForm({
    resolver: yupResolver(schemaAddPackage),
  });

  const onSubmit = (data) => {
    const [Inclusions, Exclusions] = inclusionsList;
    const locationList = selectedLocation.map((one) => {
      return one.value;
    });
    const packageData = {
      title: data.title,
      descrption: data.discription,
      price: data.price,
      days: data.duration,
      pick_drop: data.pickup,
      availablitiy: data.tourAvaliability,
      category: selectedCatogry.value,
      location: locationList,
      highlights: mainAddressList.map((i) => i),
      inclusions: Inclusions.subInclusions,
      exclusions: Exclusions.subInclusions,
      hotelActivities: hotelActivities.map((i) => JSON.stringify(i)),
      images: urlImages,
      maincategory: data.maincategory,
      typeItenary: data.typeItenary,
    };
    dispatch(addPackages(packageData))
      .unwrap()
      .then((res) => {
        if (res) {
          toast.success("Done Add Packages");
          navigation("/allPackages");
        }
      })
      .catch((err) => {
        toast.error(
          err.message ? err.message : "Somthing Wrong please Tray Again Later"
        );
      });
  };

  return (
    <div className="flex">
      <Conatiner>
        <ToastContainer
          position="top-center"
          autoClose={2000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
          theme="light"
        />
        <form onSubmit={handleSubmit(onSubmit)}>
          {/* Start Overview */}
          <div
            className={`border ${
              errors.title?.message || errors.discription?.message
                ? "border-red-300"
                : "border-green-300"
            }  px-5 pb-1 mb-10 pt-8 rounded relative`}
          >
            <TitleSectionInputs
              message={errors.title?.message || errors.discription?.message}
            >
              Overview
            </TitleSectionInputs>
            <div className="md:flex gap-2 justify-around">
              <div className="flex-1">
                <div className="relative max-w-full rounded-l-md">
                  <input
                    placeholder="Enter Title"
                    className="p-2 border-2 text-[15px] pl-9 w-full rounded-l-md rounded-r-md focus:outline-none dark:text-black"
                    type="text"
                    {...register("title")}
                  />
                  <IconsSectionInput>
                    <MdTitle />
                  </IconsSectionInput>
                </div>
                <ErrorValidation message={errors.title?.message} mt={"mt-3"} />
              </div>

              <div className="flex-1">
                <div className="relative max-w-full  overflow-hidden rounded-l-md">
                  <textarea
                    placeholder="Enter Discription"
                    name="discription"
                    rows="1"
                    cols="50"
                    className="p-2 border-2 max-h-[70px] min-h-[40px] text-[15px] pl-9 w-full rounded-l-md rounded-r-md dark:text-black focus:outline-none"
                    {...register("discription")}
                  />
                  <IconsSectionInput textarea={"true"}>
                    <RiArticleLine />
                  </IconsSectionInput>
                </div>
                <ErrorValidation message={errors.discription?.message} />
              </div>
            </div>

            <div className="md:flex gap-2 justify-around mt-2">
              <div className="flex-1">
                <div className="relative max-w-full rounded-l-md">
                  <input
                    placeholder="Enter Price"
                    className="p-2 border-2 text-[15px] pl-9 w-full rounded-l-md rounded-r-md focus:outline-none dark:text-black"
                    type="number"
                    {...register("price")}
                  />
                  <IconsSectionInput>
                    <RiPriceTag2Line />
                  </IconsSectionInput>
                </div>
                <ErrorValidation message={errors.price?.message} />
              </div>

              <div className="flex-1">
                <div className="relative max-w-full rounded-l-md">
                  <input
                    placeholder="Enter Duration"
                    className="p-2 border-2 text-[15px] pl-9 w-full rounded-l-md rounded-r-md focus:outline-none dark:text-black"
                    type="text"
                    {...register("duration")}
                  />
                  <IconsSectionInput>
                    <TbClockHour4 />
                  </IconsSectionInput>
                </div>
                <ErrorValidation message={errors.duration?.message} />
              </div>

              <div className="flex-1">
                <div className="relative max-w-full rounded-l-md">
                  {loadingLocation ? (
                    "Loading..."
                  ) : (
                    <Select
                      options={optionsLocations}
                      isMulti
                      required
                      value={selectedLocation}
                      onChange={handleSelectLocation}
                      isSearchable={true}
                      placeholder="Select Location"
                      className=" border-2 text-[15px] rounded-l-md rounded-r-md focus:outline-none dark:text-black "
                    />
                  )}
                </div>
              </div>
            </div>

            <div className="md:flex gap-2 justify-around mt-2">
              <div className="flex-1">
                <div className="relative max-w-full rounded-l-md">
                  <input
                    placeholder="Enter Tour Avaliability"
                    className="p-2 border-2 text-[15px] pl-9 w-full rounded-l-md rounded-r-md focus:outline-none dark:text-black"
                    type="text"
                    {...register("tourAvaliability")}
                  />
                  <IconsSectionInput>
                    <MdOutlineCalendarMonth />
                  </IconsSectionInput>
                </div>
                <ErrorValidation message={errors.tourAvaliability?.message} />
              </div>

              <div className="flex-1">
                <div className="relative max-w-full rounded-l-md">
                  <input
                    placeholder="Enter Pick-up & Drope-off"
                    className="p-2 border-2 text-[15px] pl-9 w-full rounded-l-md rounded-r-md focus:outline-none dark:text-black"
                    type="text"
                    {...register("pickup")}
                  />
                  <IconsSectionInput>
                    <RiCarWashingLine />
                  </IconsSectionInput>
                </div>
                <ErrorValidation message={errors.pickup?.message} />
              </div>
            </div>
            <div className="md:flex gap-2 justify-around mt-2">
              <div className="flex-1">
                <div className="relative max-w-full rounded-l-md">
                  <Controller
                    name="maincategory"
                    control={control}
                    render={({ field: { onChange, ref } }) => (
                      <Select
                        options={mainCategory}
                        ref={ref}
                        required
                        onChange={(e) => {
                          setSelectedMainCat(e.value);
                          onChange(e.value);
                        }}
                        isSearchable={true}
                        placeholder="Select Main Catogry"
                        className=" border-2 text-[15px] rounded-l-md rounded-r-md focus:outline-none dark:text-black"
                      />
                    )}
                  />
                </div>
              </div>

              <div className="flex-1">
                <div className="relative max-w-full rounded-l-md">
                  {loadingCategory ? (
                    "Loading..."
                  ) : (
                    <Select
                      options={optionsCatogry}
                      required
                      value={selectedCatogry}
                      onChange={handleSelectCatogry}
                      isSearchable={true}
                      placeholder="Select Catogry"
                      className=" border-2 text-[15px] rounded-l-md rounded-r-md focus:outline-none dark:text-black"
                    />
                  )}
                </div>
              </div>
            </div>
          </div>
          {/* End Overview */}

          {/* Start Highlights */}
          <div
            className={`border ${
              errors.mainAddress?.message || errors.subAddress?.message
                ? "border-red-300"
                : "border-green-300"
            }  px-5 pb-1 mb-10 pt-8 rounded relative`}
          >
            <TitleSectionInputs
              message={
                errors.mainAddress?.message || errors.subAddress?.message
              }
            >
              Itenary
            </TitleSectionInputs>
            <div className="flex gap-2">
              <div className="flex-1 justify-around">
                <button
                  onClick={(e) => handleAddMainAddress(e)}
                  className="bg-blue-500 px-3 py-1 flex m-auto mb-2 rounded border-2 border-blue-500 hover:border-2 hover:bg-blue-400 duration-500 "
                >
                  Add New Itenary
                </button>
                <div className="w-full my-3 flex justify-center">
                  <Controller
                    name="typeItenary"
                    control={control}
                    render={({ field: { onChange, ref } }) => (
                      <Select
                        options={[
                          { value: "normalItenary", label: "Normal Itenary" },
                          { value: "multiItenary", label: "Multi Itenary" },
                        ]}
                        ref={ref}
                        required
                        onChange={(e) => onChange(e.value)}
                        isSearchable={true}
                        placeholder="Select Type Itenary"
                        className=" border-2 text-[15px] rounded-l-md w-[80%] rounded-r-md focus:outline-none dark:text-black"
                      />
                    )}
                  />
                </div>
                {mainAddressList.map((main, index) => (
                  <div
                    key={index}
                    className="mb-8 border-2 p-5 rounded border-blue-300 pb-2"
                  >
                    <div className="relative">
                      <p className="absolute border-2 h-6 w-6 flex justify-center items-center border-blue-500 bg-white rounded-full dark:text-black -top-8 -left-8">
                        {index + 1}
                      </p>
                      <button
                        disabled={index < 1}
                        onClick={(e) => handleRemoveMainAddress(e, index)}
                        className="absolute border-2 h-8 w-8 flex justify-center items-center border-red-100 bg-red-100 text-red-600 cursor-pointer hover:bg-red-600 hover:text-red-100 duration-500 rounded-full -top-8 -right-8 "
                      >
                        <CiCircleRemove size={30} />
                      </button>
                      <div className="relative max-w-full rounded-l-md">
                        <input
                          name={nameMainAddres}
                          placeholder={`Enter Itenary ${index + 1}`}
                          className="p-2 border-2 text-[15px] pl-9 w-full rounded-l-md rounded-r-md focus:outline-none dark:text-black"
                          type="text"
                          required
                          value={main.mainAddres}
                          onChange={(e) => handleInputChange(e.target, index)}
                        />
                        <IconsSectionInput>
                          <MdAddLocationAlt />
                        </IconsSectionInput>
                      </div>
                    </div>

                    <div className="flex my-1">
                      <div className="flex-1 relative max-w-full overflow-hidden rounded-l-md">
                        <Editor
                          apiKey={
                            "x4jw6slw7ux290c6sxls9ox3hr27rv6gr2p65f46941h00ic"
                          }
                          value={main.subAddress}
                          onEditorChange={(e) =>
                            handleInputChange(
                              { name: "subaddress", value: e },
                              index
                            )
                          }
                          initialValue={`Enter Content Itenary ${index + 1}`}
                        />
                        <div
                          dangerouslySetInnerHTML={createMarkup(
                            main.subAddress
                          )}
                        ></div>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
          {/* End Highlights */}

          {/* Start Inclusions */}
          <div
            className={`border ${
              errors.mainAddress?.message || errors.subAddress?.message
                ? "border-red-300"
                : "border-green-300"
            }  px-5 pb-1 mb-10 pt-14 rounded relative`}
          >
            <TitleSectionInputs
              message={
                errors.mainAddress?.message || errors.subAddress?.message
              }
            >
              Inclusions
            </TitleSectionInputs>
            <div className="flex gap-2">
              <div className="flex-1 justify-around">
                {inclusionsList.map((main, index) => (
                  <div
                    key={index}
                    className="mb-8 border-2 p-5 rounded border-blue-300 pb-2"
                  >
                    <div className="relative">
                      <p className="absolute text-sm border-2 px-2 flex justify-center items-center border-blue-500 bg-white rounded-full dark:text-black  -top-8 -left-8">
                        {index == 0 ? "Tour Inclusions" : "Tour Exclusions "}{" "}
                      </p>
                    </div>

                    {main.subInclusions.map((sub, i) => (
                      <div key={i} className="flex gap-1 my-1">
                        <div className="flex-1 relative max-w-full rounded-l-md">
                          <input
                            name={sub}
                            placeholder={`Enter ${
                              index == 0
                                ? "Tour Inclusions"
                                : "Tour  Exclusions"
                            }`}
                            className="p-2 border-2 text-[15px] pl-9 w-full rounded-l-md rounded-r-md focus:outline-none dark:text-black"
                            type="text"
                            required
                            value={sub}
                            onChange={(e) =>
                              handleInputInclusionsChange(e, i, index)
                            }
                          />
                          <IconsSectionInput>
                            <span className="border border-black h-5 w-5 text-sm flex justify-center items-center rounded-full">
                              {i + 1}
                            </span>
                          </IconsSectionInput>
                        </div>
                        <button
                          disabled={i < 1}
                          onClick={(e) =>
                            handleRemoveSubInclusions(e, i, index)
                          }
                          className="g-red-200  rounded px-2 text-red-900 hover:bg-red-700 hover:text-red-100 duration-300"
                        >
                          <CiCircleRemove size={25} />
                        </button>
                      </div>
                    ))}
                    <button
                      onClick={(e) => handleAddSubInclusions(e, index)}
                      className="bg-green-400 w-full py-1 mt-2 rounded hover:bg-green-300 duration-500"
                    >
                      {index == 0
                        ? "Add New Tour Inclusions"
                        : "Add New Tour Exclusions"}
                    </button>
                  </div>
                ))}
              </div>
            </div>

            {/* Start Hotel Settings  */}
            <div className="mb-8 border-2 p-5 rounded border-blue-300 pb-2">
              <div className="relative">
                <p className="absolute text-sm border-2 px-2 flex justify-center items-center border-blue-500 bg-white rounded-full dark:text-black -top-8 -left-8">
                  Hotel Activatis
                </p>
              </div>

              <div>
                <button
                  value="wifi"
                  name="0"
                  onClick={(e) => handleActive(e)}
                  className={`${
                    hotelActivities[0].wifi
                      ? "bg-green-600 hover:bg-green-600"
                      : "bg-black"
                  } relative bg-black text-white h-10 w-32 mr-1 ml-6 rounded hover:bg-gray-800 duration-500`}
                >
                  <span className="absolute px-2 rounded bg-black border-r-2 border-white text-white flex items-center h-full top-0 -left-5">
                    {hotelActivities[0].wifi ? (
                      <BiWifi size={25} />
                    ) : (
                      <BiWifiOff size={25} />
                    )}
                  </span>
                  WiFi
                </button>
                <button
                  value="pool"
                  name="1"
                  onClick={(e) => handleActive(e)}
                  className={`${
                    hotelActivities[1].pool
                      ? "bg-green-600 hover:bg-green-600"
                      : "bg-black"
                  } relative bg-black text-white h-10 w-32 mr-1 ml-6 rounded hover:bg-gray-800 duration-500`}
                >
                  <span className="absolute px-2 rounded bg-black border-r-2 border-white text-white flex items-center h-full top-0 -left-5">
                    {hotelActivities[1].pool ? (
                      <MdPool size={25} />
                    ) : (
                      <TbPoolOff size={25} />
                    )}
                  </span>
                  Pool
                </button>
                <button
                  value="laundry"
                  name="2"
                  onClick={(e) => handleActive(e)}
                  className={`${
                    hotelActivities[2].laundry
                      ? "bg-green-600 hover:bg-green-600"
                      : "bg-black"
                  } relative bg-black text-white h-10 w-32 mr-1 ml-6 rounded hover:bg-gray-800 duration-500`}
                >
                  <span className="absolute px-2 rounded bg-black border-r-2 border-white text-white flex items-center h-full top-0 -left-5">
                    {hotelActivities[2].laundry ? (
                      <MdLocalLaundryService size={25} />
                    ) : (
                      <TbWashTumbleOff size={25} />
                    )}
                  </span>
                  Laundry
                </button>
                <button
                  value="gym"
                  name="3"
                  onClick={(e) => handleActive(e)}
                  className={`${
                    hotelActivities[3].gym
                      ? "bg-green-600 hover:bg-green-600"
                      : "bg-black"
                  } relative bg-black text-white h-10 w-32 mr-1 ml-6 rounded hover:bg-gray-800 duration-500`}
                >
                  <span className="absolute px-2 rounded bg-black border-r-2 border-white text-white flex items-center h-full top-0 -left-5">
                    {hotelActivities[3].gym ? (
                      <CgGym size={25} />
                    ) : (
                      <MdLeakRemove size={25} />
                    )}
                  </span>
                  GYM
                </button>
                <button
                  value="restaurant"
                  name="4"
                  onClick={(e) => handleActive(e)}
                  className={`${
                    hotelActivities[4].restaurant
                      ? "bg-green-600 hover:bg-green-600"
                      : "bg-black"
                  } relative bg-black text-white h-10 w-36 mr-1 ml-6 rounded hover:bg-gray-800 duration-500`}
                >
                  <span className="absolute px-2 rounded bg-black border-r-2 border-white text-white flex items-center h-full top-0 -left-5">
                    {hotelActivities[4].restaurant ? (
                      <MdRestaurantMenu size={25} />
                    ) : (
                      <FaCreativeCommonsSampling size={25} />
                    )}
                  </span>
                  Restaurant
                </button>
                <button
                  value="beachfront"
                  name="5"
                  onClick={(e) => handleActive(e)}
                  className={`${
                    hotelActivities[5].beachfront
                      ? "bg-green-600 hover:bg-green-600"
                      : "bg-black"
                  } relative bg-black text-white h-10 w-40 mr-1 mt-2 mb-2 ml-6 rounded hover:bg-gray-800 duration-500`}
                >
                  <span className="absolute px-2 rounded bg-black border-r-2 border-white text-white flex items-center h-full top-0 -left-5">
                    {hotelActivities[5].beachfront ? (
                      <FaUmbrellaBeach size={25} />
                    ) : (
                      <RxShadowNone size={25} />
                    )}
                  </span>
                  Beachfront
                </button>
                <button
                  value="romService"
                  name="6"
                  onClick={(e) => handleActive(e)}
                  className={`${
                    hotelActivities[6].romService
                      ? "bg-green-600 hover:bg-green-600"
                      : "bg-black"
                  } relative bg-black text-white h-10 w-40 mr-1 ml-6 rounded hover:bg-gray-800 duration-500`}
                >
                  <span className="absolute px-2 rounded bg-black border-r-2 border-white text-white flex items-center h-full top-0 -left-5">
                    {hotelActivities[6].romService ? (
                      <MdOutlineRoomService size={25} />
                    ) : (
                      <RxShadowNone size={25} />
                    )}
                  </span>
                  Rom Service
                </button>
                <button
                  value="familyRooms"
                  name="7"
                  onClick={(e) => handleActive(e)}
                  className={`${
                    hotelActivities[7].familyRooms
                      ? "bg-green-600 hover:bg-green-600"
                      : "bg-black"
                  } relative bg-black text-white h-10 w-44 mr-1 ml-6 rounded hover:bg-gray-800 duration-500`}
                >
                  <span className="absolute px-2 rounded bg-black border-r-2 border-white text-white flex items-center h-full top-0 -left-5">
                    {hotelActivities[7].familyRooms ? (
                      <MdFamilyRestroom size={25} />
                    ) : (
                      <RxShadowNone size={25} />
                    )}
                  </span>
                  Family Rooms
                </button>
              </div>
            </div>
            {/* End Hotel Settings  */}
          </div>
          {/* End Inclusions */}

          {/* Start Images */}
          <div
            className={`border ${
              errors.mainAddress?.message || errors.subAddress?.message
                ? "border-red-300"
                : "border-green-300"
            }  px-5 pb-1 mb-10 pt-14 rounded relative`}
          >
            <TitleSectionInputs
              message={
                errors.mainAddress?.message || errors.subAddress?.message
              }
            >
              Images
            </TitleSectionInputs>

            <div>
              {urlImages.map((_, index) => (
                <div key={index} className="relative mb-3">
                  <input
                    className="p-2 border-2 text-[15px] pl-9 w-full rounded-l-md rounded-r-md focus:outline-none dark:text-black"
                    type="url"
                    placeholder={`Enter Url Image ${index + 1}`}
                    name="images"
                    onChange={(e) => handleUbloadFiles(e.target.value, index)}
                    required={index + 1 == 1 || index + 1 ==2 ? true : false}
                    pattern="^https?:\/\/res\.cloudinary\.com.*"
                  />
                  <IconsSectionInput>
                    <IoIosImages />
                  </IconsSectionInput>
                </div>
              ))}
            </div>
          </div>

          <div className="w-full flex justify-center items-center">
            <Button
              disabled={loadingAdd}
              type={"submit"}
              value={
                loadingAdd ? (
                  <Oval
                    width={20}
                    height={20}
                    color="#FFF"
                    strokeWidth={6}
                    secondaryColor="#EEE"
                  />
                ) : (
                  "Add Package"
                )
              }
            />
          </div>
        </form>
      </Conatiner>
    </div>
  );
}

export default AddPackage;
