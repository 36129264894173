import React from "react";
import { FaUserAlt } from "react-icons/fa";
import { MdEmail , MdLocationOn } from "react-icons/md";
import { GoDeviceMobile } from "react-icons/go";
import { BiWorld, BiLogIn, BiLogOut, BiChild } from "react-icons/bi";
import { AiFillStar } from "react-icons/ai";
import { ImManWoman } from "react-icons/im";
import { BsFillInfoCircleFill } from "react-icons/bs";

function CustomePackageDetails({data}) {
  return (
    <div className="w-full flex flex-col justify-center">
      {/* Personal Details */}
      <div className=" w-full border-b-2 border-b-main-light p-2">
        <h3 className="font-bold text-xl mb-2">Personal Details</h3>
        <div className="w-full flex justify-around items-center flex-col px-2 gap-y-2 sm:gap-y-3 md:gap-y-3 lg:gap-y-3 xl:gap-y-3 ">
          <div className="flex flex-col sm:flex-row md:flex-row lg:flex-row xl:flex-row justify-center items-center gap-x-2 gap-y-2 w-full">
            {/* Name */}
            <div className="flex flex-row justify-start pr-2 sm:pr-0 md:pr-0 lg:pr-0 xl:pr-0 items-center gap-x-1 w-full">
              <span>
                <FaUserAlt size={20} className="text-main" />
              </span>
              <span className="font-semibold tracking-wide">Name:</span>
              <span className="font-normal tracking-wide">{data.name}</span>
            </div>
            {/* Address */}
            <div className="flex flex-row justify-start pr-2 sm:pr-0 md:pr-0 lg:pr-0 xl:pr-0 items-center gap-x-1 w-full">
              <span>
                <MdEmail size={20} className="text-main" />
              </span>
              <span className="font-semibold tracking-wide">Email Address:</span>
              <span className="font-normal tracking-wide">{data.emailAddress}</span>
            </div>
          </div>

          <div className="flex flex-col sm:flex-row md:flex-row lg:flex-row xl:flex-row justify-center items-center gap-x-2 gap-y-2 w-full">
            {/* Phone */}
            <div className="flex flex-row justify-start pr-2 sm:pr-0 md:pr-0 lg:pr-0 xl:pr-0 items-center gap-x-1 w-full">
              <span>
                <GoDeviceMobile size={20} className="text-main" />
              </span>
              <span className="font-semibold tracking-wide">Phone:</span>
              <span className="font-normal tracking-wide">{data?.phoneNumber}</span>
            </div>

            {/* Nationality */}
            <div className="flex flex-row justify-start pr-2 sm:pr-0 md:pr-0 lg:pr-0 xl:pr-0 items-center gap-x-1 w-full">
              <span>
                <BiWorld size={20} className="text-main" />
              </span>
              <span className="font-semibold tracking-wide">Nationality:</span>
              <span className="font-normal tracking-wide">{data.nationality}</span>
            </div>
          </div>
        </div>
      </div>
      {/* Trip */}
      <div className=" w-full border-b-2 border-b-main-light p-2">
        <h3 className="font-bold text-xl mb-2">Trip Details</h3>
        <div className="w-full flex justify-around items-center flex-col px-2 gap-y-2 sm:gap-y-3 md:gap-y-3 lg:gap-y-3 xl:gap-y-3 ">
          <div className="flex flex-col sm:flex-row md:flex-row lg:flex-row xl:flex-row justify-center items-center gap-x-2 gap-y-2 w-full">
            {/* Destination */}
            <div className="flex flex-row justify-start pr-2 sm:pr-0 md:pr-0 lg:pr-0 xl:pr-0 items-center gap-x-1 w-full">
              <span>
                <MdLocationOn size={20} className="text-main" />
              </span>
              <span className="font-semibold tracking-wide">Destination:</span>
              <span className="font-normal tracking-wide">{data.distination}</span>
            </div>
            {/* Accommodation */}
            <div className="flex flex-row justify-start pr-2 sm:pr-0 md:pr-0 lg:pr-0 xl:pr-0 items-center gap-x-1 w-full">
              <span>
                <AiFillStar size={20} className="text-main" />
              </span>
              <span className="font-semibold tracking-wide">
                Accommodation:
              </span>
              <span className="font-normal tracking-wide">{data.accommodation}</span>
            </div>
          </div>

          <div className="flex flex-col sm:flex-row md:flex-row lg:flex-row xl:flex-row justify-center items-center gap-x-2 gap-y-2 w-full">
            {/* Check in */}
            <div className="flex flex-row justify-start pr-2 sm:pr-0 md:pr-0 lg:pr-0 xl:pr-0 items-center gap-x-1 w-full">
              <span>
                <BiLogIn size={20} className="text-main" />
              </span>
              <span className="font-semibold tracking-wide">Check in:</span>
              <span className="font-normal tracking-wide">{data.checkIn}</span>
            </div>
            {/* Check Out */}
            <div className="flex flex-row justify-start pr-2 sm:pr-0 md:pr-0 lg:pr-0 xl:pr-0 items-center gap-x-1 w-full">
              <span>
                <BiLogOut size={20} className="text-main" />
              </span>
              <span className="font-semibold tracking-wide">Check out:</span>
              <span className="font-normal tracking-wide">{data.checkOut}</span>
            </div>
          </div>
        </div>
      </div>

      {/* Guests */}
      <div className=" w-full border-b-2 border-b-main-light p-2">
        <h3 className="font-bold text-xl mb-2">Guests</h3>
        <div className="w-full flex justify-around items-center flex-col px-2 gap-y-2 sm:gap-y-3 md:gap-y-3 lg:gap-y-3 xl:gap-y-3 ">
          <div className="flex flex-col sm:flex-row md:flex-row lg:flex-row xl:flex-row justify-center items-center gap-x-2 gap-y-2 w-full">
            {/* Adults */}
            <div className="flex flex-row justify-start pr-2 sm:pr-0 md:pr-0 lg:pr-0 xl:pr-0 items-center gap-x-1 w-full">
              <span>
                <ImManWoman size={20} className="text-main" />
              </span>
              <span className="font-semibold tracking-wide">Adults:</span>
              <span className="font-normal tracking-wide">{data?.guests?.numberOfAdults}</span>
            </div>
            {/* Children */}
            <div className="flex flex-row justify-start pr-2 sm:pr-0 md:pr-0 lg:pr-0 xl:pr-0 items-center gap-x-1 w-full">
              <span>
                <BiChild size={20} className="text-main" />
              </span>
              <span className="font-semibold tracking-wide">Children:</span>
              <span className="font-normal tracking-wide">{data?.guests?.numberOfChildren}</span>
            </div>
          </div>

          <div className="flex justify-center gap-x-2 gap-y-2 w-full">
            {/* Additional Details */}
            <div className="flex flex-col justify-start pr-2 sm:pr-0 md:pr-0 lg:pr-0 xl:pr-0 items-center gap-x-1 w-full">
              <div className="w-full flex flex-row gap-x-1">
                <span>
                  <BsFillInfoCircleFill size={20} className="text-main" />
                </span>
                <span className="font-semibold tracking-wide">
                  Additional Details:
                </span>
              </div>
              <p className="font-normal tracking-wide max-w-[500px] max-h-[100px] overflow-auto border border-x-slate-100 rounded-lg p-2 ml-7">
                {data.additionalDetails}
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default CustomePackageDetails;
