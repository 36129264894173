import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import AxiosInstance from "../AxiosInstance";

export const addPackages = createAsyncThunk(
  "package/addPackages",
  async (packageData, { rejectWithValue }) => {
    try {
      const { data } = await AxiosInstance.post("package/create", packageData );

      return data;
    } catch (error) {
      return rejectWithValue(error.response ? error.response.data : error);
    }
  }
);

export const getAllPackages = createAsyncThunk(
  "package/all",
  async ({ pageNumber, signal }, { rejectWithValue }) => {
    try {
      const { data } = await AxiosInstance.get(
        `package?pageNumber=${pageNumber}`,
        { signal }
      );

      return data;
    } catch (error) {
      return rejectWithValue(error.response ? error.response.data : error);
    }
  }
);

export const updatePackage = createAsyncThunk(
  "package/update",
  async ({ id, packageData }, { rejectWithValue, dispatch }) => {
    try {
      const { data } = await AxiosInstance.put(`package/${id}`, packageData);
      dispatch(getPackageWithId({ id }));
      return data;
    } catch (error) {
      return rejectWithValue(error.response ? error.response.data : error);
    }
  }
);

export const deletePackage = createAsyncThunk(
  "package/delete",
  async (id, { rejectWithValue }) => {
    try {
      // eslint-disable-next-line no-unused-vars
      const { data } = await AxiosInstance.delete(`package/${id}`);

      return id;
    } catch (error) {
      return rejectWithValue(error.response ? error.response.data : error);
    }
  }
);

export const sendNotificationPackage = createAsyncThunk(
  "package/noti",
  async (packageData, { rejectWithValue }) => {
    try {
      // eslint-disable-next-line no-unused-vars
      const { data } = await AxiosInstance.post("mail/post", packageData);

      return data;
    } catch (error) {
      return rejectWithValue(error.response ? error.response.data : error);
    }
  }
);

export const getPackageWithId = createAsyncThunk(
  "package/withId",
  async ({ id, signal }, { rejectWithValue }) => {
    try {
      const { data } = await AxiosInstance.get(`package/get/${id}`, { signal });

      return data;
    } catch (error) {
      return rejectWithValue(error.response ? error.response.data : error);
    }
  }
);

export const searchPackages = createAsyncThunk(
  "package/search",
  async ({ text, signal }, { rejectWithValue }) => {
    try {
      const { data } = await AxiosInstance.get(`package/serach/${text}`, { signal });

      return data;
    } catch (error) {
      return rejectWithValue(error.response ? error.response.data : error);
    }
  }
);

const packageSlice = createSlice({
  name: "package",
  initialState: {
    data: [],
    loading: false,
    loadingAdd:false,
    error: null,
    mailLoading: false,
    packageData: {},
    loadingpackageId: false,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(addPackages.pending, (state) => {
        state.loadingAdd = true;
      })
      .addCase(addPackages.fulfilled, (state, action) => {
        state.loadingAdd = false;
        state.error = null;
      })
      .addCase(addPackages.rejected, (state, action) => {
        state.loadingAdd = false;
        state.error = action.payload;
      })
      // get All
      .addCase(getAllPackages.pending, (state) => {
        state.loading = true;
      })
      .addCase(getAllPackages.fulfilled, (state, action) => {
        state.loading = false;
        state.error = null;
        state.data = action.payload;
      })
      .addCase(getAllPackages.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
      // delete Package
      .addCase(deletePackage.pending, (state) => {
        state.loading = true;
      })
      .addCase(deletePackage.fulfilled, (state, acthion) => {
        state.loading = false;
        state.error = null;
        state.data.data = state.data.data.filter(
          (i) => i._id !== acthion.payload
        );
      })
      .addCase(deletePackage.rejected, (state, acthion) => {
        state.loading = false;
        state.error = acthion.payload;
      })
      // send Notification
      .addCase(sendNotificationPackage.pending, (state) => {
        state.mailLoading = true;
      })
      .addCase(sendNotificationPackage.fulfilled, (state, acthion) => {
        state.mailLoading = false;
      })
      .addCase(sendNotificationPackage.rejected, (state, acthion) => {
        state.mailLoading = false;
      })
      // Update Package
      .addCase(updatePackage.pending, (state) => {
        state.loading = true;
      })
      .addCase(updatePackage.fulfilled, (state, action) => {
        state.loading = false;
      })
      .addCase(updatePackage.rejected, (state, action) => {
        state.loading = false;
      })
      // get Package With Id
      .addCase(getPackageWithId.pending, (state) => {
        state.loadingpackageId = true;
      })
      .addCase(getPackageWithId.fulfilled, (state, action) => {
        state.loadingpackageId = false;
        state.error = null;
        state.packageData = action.payload.data;
      })
      .addCase(getPackageWithId.rejected, (state, action) => {
        state.loadingpackageId = false;
        state.error = action.payload;
      })

      // search Package
      .addCase(searchPackages.pending, (state) => {
        state.loading = true;
      })
      .addCase(searchPackages.fulfilled, (state, action) => {
        state.loading = false;
        state.error = null;
        state.data = action.payload;
      })
      .addCase(searchPackages.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
  },
});

export default packageSlice.reducer;
