/* eslint-disable eqeqeq */
import Conatiner from "../components/Conatiner";
import Input from "../components/Input.com";
import { MdTitle } from "react-icons/md";
import IconsSectionInput from "../components/addPackage/IconsSectionInput";
import { RiArticleLine } from "react-icons/ri";
import { useFieldArray, useForm } from "react-hook-form";
import TitleSectionInputs from "../components/addPackage/TitleSectionInputs";
import { BiHeading } from "react-icons/bi";
import { IoIosImages } from "react-icons/io";
import Button from "../components/Button.com";
import { CiCircleRemove } from "react-icons/ci";
import { addBlog } from "../redux/slicers/blog";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import { Oval } from "react-loader-spinner";

function AddBlog() {
  const dispatch = useDispatch();
  const { loadingAdd } = useSelector((state) => state.blogs);

  const { register, handleSubmit, control , reset } = useForm({
    defaultValues: {
      title: "",
      cover: "",
      discription: "",
      pragraph: [{ header: "", text: ["• ", "• "], img: "" }],
    },
  });

  const { append, fields, remove } = useFieldArray({
    control,
    name: "pragraph",
  });

  // Start Handel pragraph
  const handleAddParagrph = (e) => {
    e.preventDefault();
    append({ header: "", text: ["• ", "• "], img: "" });
  };

  const handleRemovepragraph = (e, index) => {
    e.preventDefault();
    remove(index);
  };

  const onSubmit = (values) => {
    dispatch(addBlog(values))
      .unwrap()
      .then((res) => {
        reset()
        toast.success("Done Add Blog")})
      .catch((err) => toast.error(err.message));
  };

  return (
    <Conatiner>
      <form
        onSubmit={handleSubmit(onSubmit)}
        className="flex flex-col items-center justify-center gap-y-3 w-[90%] px-6 mx-auto"
      >
        {/* Title */}
        <div className="flex-1 w-full">
          <Input
            placeholder={"Enter Title"}
            icon={<MdTitle size={22} className="dark:text-black" />}
            required={true}
            register={{ ...register("title") }}
          />
        </div>
        {/* Cover */}
        <div className="flex-1 w-full">
          <Input
            placeholder={"Enter Cover Url"}
            icon={<IoIosImages size={22} className="dark:text-black" />}
            required={true}
            type={"url"}
            register={{ ...register("cover") }}
          />
        </div>

        {/* Description */}
        <div className="flex-1 w-full">
          <div className="relative max-w-full overflow-hidden rounded-l-md">
            <textarea
              placeholder="Enter Discription"
              name="discription"
              rows="2"
              cols="50"
              className="p-2 border-2 max-h-[150px] min-h-[40px] dark:text-black text-[15px] pl-9 w-full rounded-l-md rounded-r-md focus:outline-none"
              {...register("discription")}
              required
            />
            <IconsSectionInput textarea={"true"}>
              <RiArticleLine size={22} />
            </IconsSectionInput>
          </div>
        </div>

        {/* Pragraph */}
        <div className="w-full border border-green-300 px-5 pb-1 mb-5 pt-8 rounded relative mt-2.5">
          <TitleSectionInputs>Pragraph</TitleSectionInputs>
          <div>
            {/* Add */}
            <button
              onClick={(e) => handleAddParagrph(e)}
              className="bg-blue-500 px-3 py-1 flex m-auto mb-2 rounded border-2 border-blue-500 hover:border-2 hover:bg-blue-400 duration-500 "
            >
              Add New pragraph
            </button>
            {fields.map((item, index) => (
              <div
                key={item.id}
                className="relative p-3 border border-blue-300 mb-8"
              >
                <p className="absolute border-2 h-6 w-6 flex justify-center items-center dark:text-black border-blue-500 bg-white rounded-full -top-[0.8rem] -left-[0.8rem]">
                  {index + 1}
                </p>
                {/* Remove */}
                <button
                  disabled={index < 1}
                  onClick={(e) => handleRemovepragraph(e, index)}
                  className="absolute border-2 h-8 w-8 flex justify-center items-center border-red-100 bg-red-100 text-red-600 cursor-pointer hover:bg-red-600 hover:text-red-100 duration-500 rounded-full -top-4 -right-4 "
                >
                  <CiCircleRemove size={30} />
                </button>

                {/* Header */}
                <div className="flex-1 w-full">
                  <Input
                    placeholder={"Enter Header"}
                    icon={<BiHeading size={22} className="dark:text-black" />}
                    required={true}
                    register={{ ...register(`pragraph.${index}.header`) }}
                  />
                </div>

                {/* Text */}
                <NestedArrayOfText
                  {...{ control, register }}
                  nestedIndex={index}
                />

                {/* Images */}
                <div>
                  <div className="relative mb-3">
                    <Input
                      placeholder={"Enter Image Url"}
                      icon={
                        <IoIosImages size={22} className="dark:text-black" />
                      }
                      required={true}
                      type={"url"}
                      register={{ ...register(`pragraph.${index}.img`) }}
                    />
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>

        <Button
          disabled={loadingAdd}
          type={"submit"}
          value={
            loadingAdd ? (
              <Oval
                width={20}
                height={20}
                color="#FFF"
                strokeWidth={6}
                secondaryColor="#EEE"
              />
            ) : (
              "Add Blog"
            )
          }
        />
      </form>
    </Conatiner>
  );
}

export default AddBlog;

export const NestedArrayOfText = ({ register, nestedIndex, control }) => {
  const { append, fields, remove } = useFieldArray({
    control,
    name: `pragraph[${nestedIndex}].text`,
  });

  const handleAddText = (e) => {
    e.preventDefault();
    append("• ");
  };

  const handelRemoveText = (e, index) => {
    e.preventDefault();
    remove(index);
  };

  return (
    <>
      {fields.map((item, index) => {
        return (
          <div
            key={item.id}
            className="flex items-center gap-x-2 w-full"
          >
            <div className="relative max-w-full w-full overflow-hidden rounded-l-md">
              <textarea
                placeholder="Enter Text pragraph"
                rows="2"
                cols="50"
                className="p-2 border-2 max-h-[150px] min-h-[40px] text-[15px] dark:text-black pl-9 w-full rounded-l-md rounded-r-md focus:outline-none"
                defaultValue={item[index]}
                {...register(`pragraph.${nestedIndex}.text[${index}]`)}
                required
              />
              <IconsSectionInput textarea={"true"}>
                <span className="border border-black h-5 w-5 text-sm flex justify-center items-center rounded-full">
                  {index + 1}
                </span>
              </IconsSectionInput>
            </div>
            <button
              disabled={index < 1}
              onClick={(e) => handelRemoveText(e, index)}
              className="g-red-200 p-2 border border-red-900 rounded px-2 text-red-600 hover:bg-red-700 hover:text-red-100 duration-300 bg-red-100"
            >
              <CiCircleRemove size={25} />
            </button>
          </div>
        );
      })}
      <button
        onClick={(e) => handleAddText(e)}
        className="bg-blue-500 px-3 py-1 flex m-auto mb-2 rounded border-2 border-blue-500 hover:border-2 hover:bg-blue-400 duration-500 "
      >
        Add New Text
      </button>
    </>
  );
};
