import { createSlice } from "@reduxjs/toolkit";


const theamSlice = createSlice({
  name:'theam',
  initialState:{
    theam:localStorage.getItem('theam') || 'light'
  },
  reducers:{
    toogelTheam:(state)=>{
      if(state.theam === 'light'){
        localStorage.setItem('theam','dark')
        document.documentElement.classList.add('dark')
        state.theam= 'dark'
      }
      else {
        localStorage.setItem('theam',"light")
        state.theam = "light"
        document.documentElement.classList.remove('dark')
      }
    }
  }
})

export const { toogelTheam } = theamSlice.actions

export default theamSlice.reducer