import React, { useEffect, useState } from "react";
import Conatiner from "../components/Conatiner";
import { GrUpdate } from "react-icons/gr";
import ModalCom from "../components/Modal.com";
import { Oval } from "react-loader-spinner";
import ButtonCom from "../components/Button.com";
import { useFieldArray, useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import { updateBlog } from "../redux/slicers/blog";
import { MdTitle, MdUpdate } from "react-icons/md";
import Input from "../components/Input.com";
import { IoIosImages } from "react-icons/io";
import IconsSectionInput from "../components/addPackage/IconsSectionInput";
import { RiArticleLine } from "react-icons/ri";
import TitleSectionInputs from "../components/addPackage/TitleSectionInputs";
import { CiCircleRemove } from "react-icons/ci";
import { BiHeading } from "react-icons/bi";
import { NestedArrayOfText } from "./AddBlog";
import { useNavigate } from "react-router-dom";

function BlogDetails() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { loadingAdd, blogData: data } = useSelector((state) => state.blogs);
  const [showUpdate, setShowUpdate] = useState(false);

  useEffect(() => {
    if (typeof window !== "undefined") {
      window.scrollTo(0, 0);
    }
    if (!data) {
      navigate(-1);
    }
  }, [data, navigate]);

  const { register, handleSubmit, control } = useForm({
    defaultValues: {
      title: data?.title,
      cover: data?.cover,
      discription: data?.discription,
      pragraph: data?.pragraph
        ? data?.pragraph.map((item) => {
            const text = item.text.map((i) => i);
            return {
              header: item?.header,
              text: text,
              img: item?.img,
            };
          })
        : ["•", "•"],
    },
  });

  const { append, fields, remove } = useFieldArray({
    control,
    name: "pragraph",
  });
  // Start Handel pragraph
  const handleAddParagrph = (e) => {
    e.preventDefault();
    append({ header: "", text: ["• ", "• "], img: "" });
  };

  const handleRemovepragraph = (e, index) => {
    e.preventDefault();
    remove(index);
  };

  const onSubmit = (values) => {
    dispatch(updateBlog({ id: data._id, blogData: values }))
      .unwrap()
      .then((res) => {
        setShowUpdate(false);
        toast.success("Done Update Blog");
      })
      .catch((err) =>
        toast.error(
          err.message ? err.message : "Somthing Wrong try Again Later"
        )
      );
  };

  return (
    <>
      {data && (
        <>
          <Conatiner>
            <div className="w-full flex justify-end gap-x-4">
              <div
                onClick={() => setShowUpdate(true)}
                className="p-2 border-2 border-blue-400 bg-blue-400 rounded cursor-pointer hover:bg-blue-200 dark:hover:text-black duration-500"
              >
                <div className="flex items-center justify-center gap-3">
                  <span>
                    <GrUpdate size={30} />
                  </span>
                  <span>Update</span>
                </div>
              </div>
            </div>

            <h2 className="pl-2 text-xl font-bold">{data?.title}</h2>
            <span className="border-b-2 border-b-main-50 w-5 block my-2"></span>
            <div className="w-full h-60">
              <img
                src={data.cover}
                alt={data.title}
                className="rounded-lg object-cover w-full h-full"
              />
            </div>
            <div>
              <h3 className="font-semibold my-3">Description :</h3>
              <p className="block w-full font-normal text-slate-700 dark:text-white border border-slate-500 rounded-lg p-3">
                {data?.discription}
              </p>
            </div>

            {/* Start pragraph */}
            <div className="w-full flex flex-col my-4 gap-y-9">
              {data.pragraph.map((item, index) => (
                <div key={index}>
                  <div className="flex gap-x-2">
                    <span className="text-center font-semibold rounded-full border w-8 h-8 flex items-center justify-center">
                      {index + 1}
                    </span>
                    <span>
                      <h3 className="pl-2 font-semibold">{item?.header}</h3>
                      <span className="border-b-2 border-b-main-50 w-5 block my-1"></span>
                    </span>
                  </div>
                  <div className="w-full h-60 my-3">
                    <img
                      src={item?.img}
                      alt={data.title}
                      className="rounded-lg object-cover w-full h-full"
                    />
                  </div>
                  <div className="w-full">
                    <ul className="w-full">
                      {item.text.map((text, index) => (
                        <li key={index} className="my-2 ">
                          {text}
                        </li>
                      ))}
                    </ul>
                  </div>
                </div>
              ))}
            </div>
          </Conatiner>

          <ModalCom
            title={data?.title}
            icon={<MdUpdate size={25} className="text-main" />}
            visible={showUpdate}
            onClose={() => setShowUpdate(false)}
            closeOnBackDroupPress={true}
          >
            <div>
              <form
                onSubmit={handleSubmit(onSubmit)}
                className="flex flex-col items-center justify-center gap-y-3 px-6 py-3"
              >
                {/* Title */}
                <div className="flex-1 w-full">
                  <Input
                    placeholder={"Enter Title"}
                    icon={<MdTitle size={22} className="dark:text-black" />}
                    required={true}
                    register={{ ...register("title") }}
                  />
                </div>
                {/* Cover */}
                <div className="flex-1 w-full">
                  <Input
                    placeholder={"Enter Cover Url"}
                    icon={<IoIosImages size={22} className="dark:text-black" />}
                    required={true}
                    type={"url"}
                    register={{ ...register("cover") }}
                  />
                </div>

                {/* Description */}
                <div className="flex-1 w-full">
                  <div className="relative max-w-full overflow-hidden rounded-l-md">
                    <textarea
                      placeholder="Enter Discription"
                      name="discription"
                      rows="2"
                      cols="50"
                      className="p-2 border-2 max-h-[150px] min-h-[40px] dark:text-black text-[15px] pl-9 w-full rounded-l-md rounded-r-md focus:outline-none"
                      {...register("discription")}
                      required
                    />
                    <IconsSectionInput textarea={"true"}>
                      <RiArticleLine size={22} />
                    </IconsSectionInput>
                  </div>
                </div>

                {/* Pragraph */}
                <div className="w-full border border-green-300 px-5 pb-1 mb-5 pt-8 rounded relative mt-2.5">
                  <TitleSectionInputs>Pragraph</TitleSectionInputs>
                  <div>
                    {/* Add */}
                    <button
                      onClick={(e) => handleAddParagrph(e)}
                      className="bg-blue-500 px-3 py-1 flex m-auto mb-2 rounded border-2 border-blue-500 hover:border-2 hover:bg-blue-400 duration-500 "
                    >
                      Add New pragraph
                    </button>
                    {fields.map((item, index) => (
                      <div
                        key={item.id}
                        className="relative p-3 border border-blue-300 mb-8"
                      >
                        <p className="absolute border-2 h-6 w-6 flex justify-center items-center dark:text-black border-blue-500 bg-white rounded-full -top-[0.8rem] -left-[0.8rem]">
                          {index + 1}
                        </p>
                        {/* Remove */}
                        <button
                          disabled={index < 1}
                          onClick={(e) => handleRemovepragraph(e, index)}
                          className="absolute border-2 h-8 w-8 flex justify-center items-center border-red-100 bg-red-100 text-red-600 cursor-pointer hover:bg-red-600 hover:text-red-100 duration-500 rounded-full -top-4 -right-4 "
                        >
                          <CiCircleRemove size={30} />
                        </button>

                        {/* Header */}
                        <div className="flex-1 w-full">
                          <Input
                            placeholder={"Enter Header"}
                            icon={
                              <BiHeading
                                size={22}
                                className="dark:text-black"
                              />
                            }
                            required={true}
                            register={{
                              ...register(`pragraph.${index}.header`),
                            }}
                          />
                        </div>

                        {/* Text */}
                        <NestedArrayOfText
                          {...{ control, register }}
                          nestedIndex={index}
                        />

                        {/* Images */}
                        <div>
                          <div className="relative mb-3">
                            <Input
                              placeholder={"Enter Image Url"}
                              icon={
                                <IoIosImages
                                  size={22}
                                  className="dark:text-black"
                                />
                              }
                              required={true}
                              type={"url"}
                              register={{
                                ...register(`pragraph.${index}.img`),
                              }}
                            />
                          </div>
                        </div>
                      </div>
                    ))}
                  </div>
                </div>

                <ButtonCom
                  disabled={loadingAdd}
                  type={"submit"}
                  value={
                    loadingAdd ? (
                      <Oval
                        width={20}
                        height={20}
                        color="#FFF"
                        strokeWidth={6}
                        secondaryColor="#EEE"
                      />
                    ) : (
                      "Update Blog"
                    )
                  }
                />
              </form>
            </div>
          </ModalCom>
        </>
      )}
    </>
  );
}

export default BlogDetails;
