import React from "react";
import { useForm } from "react-hook-form";
import {RiSearchLine} from 'react-icons/ri'

function Search({placeholder , onSubmit }) {
  const { register , handleSubmit } = useForm()
  return (
    <form 
      onSubmit={handleSubmit(onSubmit)}
      className="border-slate-300 border-2 rounded-lg relative flex items-center py-2 h-fit">
      <span className="px-3 inline-block">
       <RiSearchLine size={20} className="rotate-50 opacity-50" />
      </span>
      <input placeholder={placeholder} className="focus:outline-none dark:bg-dark" 
        {...register('title')}
        required
      />
      <button 
        type="submit"
        className="bg-white dark:bg-dark dark:text-white absolute -right-8 hover:-right-7 outline-1 hover:bg-main hover:text-white duration-200 sm:visible invisible outline outline-slate-400 rounded-lg h-full  px-3 font-medium">
        Search
      </button>
    </form>
  );
}

export default Search;
