import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import AxiosInstance from "../AxiosInstance";

export const getContactUs = createAsyncThunk("contact/get",
  async ( {pageNumper , signal } , { rejectWithValue })=>{
    try {
      const { data } = await AxiosInstance.get(`contact/${pageNumper}`,{signal: signal})

      return data
    } catch (error) {
      return rejectWithValue(error.response ? error.response.data : error )
    }
  }
)

export const updateContactUs = createAsyncThunk("contact/update",
  async ( res , { rejectWithValue , dispatch})=>{
    try {
      const { data } = await AxiosInstance.put(`contact/${res.id}`,res.data)
      dispatch(getContactUs(res.pageNumper))
      return data
    } catch (error) {
      return rejectWithValue(error.response ? error.response.data : error )
    }
  }
)

export const deleteContactUs = createAsyncThunk("contact/delete",
  async ( id , { rejectWithValue })=>{
    try {
      // eslint-disable-next-line no-unused-vars
      const data = await AxiosInstance.delete(`contact/${id}`)
      
      return id
    } catch (error) {
      return rejectWithValue(error.response ? error.response.data : error )
    }
  }
)


const contactSlice = createSlice({
  name:'contactUs',
  initialState:{
    data:[],
    loading:false,
    error:null,
    detailsContactUs:{}
  },
  reducers:{
    setDetailsContactUs:(state , acthion)=>{
      state.detailsContactUs = acthion.payload
    }
  },
  extraReducers:(builder)=>{
    builder
      .addCase(getContactUs.pending , (state)=>{
        state.loading = true
        state.error = null
      })
      .addCase(getContactUs.fulfilled , (state , acthion)=>{
        state.loading = false
        state.error = null
        state.data = acthion.payload ? acthion.payload : []
      })
      .addCase(getContactUs.rejected , (state , acthion)=>{
        state.loading = false
        state.error = acthion.payload
      })
      // delete
      .addCase(deleteContactUs.pending , (state)=>{
        state.loading = true
      })
      .addCase(deleteContactUs.fulfilled , (state , acthion)=>{
        state.loading = false
        state.error = null
        state.data.data = state.data.data.filter((e) => e._id !== acthion.payload)
      })
      .addCase(deleteContactUs.rejected , (state , acthion)=>{
        state.loading = false
        state.error = acthion.payload
      })
  }
})

export const { setDetailsContactUs } = contactSlice.actions
export default contactSlice.reducer