
import * as yup from "yup";

export const schemaAddPackage = yup.object({
    title: yup.string('Please Enter Title').required('Please Enter Title'),
    price: yup.string('Please Enter Price ').required('Please Enter Price'),
    discription: yup.string('Please Enter discription').required('Please Enter discription'),

    duration: yup.string('Please Enter Duration').required('Please Enter Duration'),
    // location: yup.string('Please Enter Location ').required('Please Enter Location'),
    // tourAvaliability: yup.string('Enter Tour Avaliability').required('Enter Tour Avaliability'),
    pickup: yup.string('Please Enter Pickup ').required('Please Enter Pickup'), 
})