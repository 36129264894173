import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import AxiosInstance from "../AxiosInstance";

export const getAllTrips = createAsyncThunk("trip/all", 
  async ( {pageNumber , signal} , { rejectWithValue } ) =>{
    try {
      const { data } = await AxiosInstance.get(`trip/${pageNumber}`, {signal:signal})

      return data
    } catch (error) {
      console.log(error)
      return rejectWithValue(error.response ? error.response.data : error)
    }
  }
)

export const deleteTrips = createAsyncThunk("trip/delete", 
  async ( id , { rejectWithValue } ) =>{
    try {
      // eslint-disable-next-line no-unused-vars
      const { data } = await AxiosInstance.delete(`trip/${id}`)

      return id
    } catch (error) {
      console.log(error)
      return rejectWithValue(error.response ? error.response.data : error)
    }
  }
)

const tripSlice = createSlice({
  name:"trips",
  initialState:{
    data:[],
    loading:false,
    error:null,
    users:[],
  },
  reducers:{
    setUsers:(state , action ) =>{
      state.users = action.payload
    }
  },
  extraReducers:(builder)=>{
    // get All trips
    builder
    // #region
      .addCase(getAllTrips.pending , (state)=>{
        state.loading = true
      })
      .addCase(getAllTrips.fulfilled , (state , action)=>{
        state.loading = false
        state.data = action.payload
        state.error = null
      })
      .addCase(getAllTrips.rejected , (state , action)=>{
        state.loading = false
        state.error = action.payload
      })
    // #endregion

    // Delete
    // #region
      .addCase(deleteTrips.pending , (state)=>{
        state.loading = true
      })
      .addCase(deleteTrips.fulfilled , (state , action)=>{
        state.loading = false
        state.data.data = state.data.data.filter((i) => i._id !== action.payload)
        state.error = null
      })
      .addCase(deleteTrips.rejected , (state , action)=>{
        state.loading = false
        state.error = action.payload
      })
    // #endregion
  }
})

export const { setUsers } = tripSlice.actions
export default tripSlice.reducer