import { configureStore } from '@reduxjs/toolkit'
import user from './slicers/user'
import customPackage from './slicers/customePackages'
import category from './slicers/category'
import contactUs from './slicers/contactUs'
import location from './slicers/location';
import packages from './slicers/packages'
import trips from './slicers/trips'
import blogs from './slicers/blog'
import theam from './slicers/theam'

const store = configureStore({
  reducer:{
    user,
    customPackage,
    category,
    contactUs,
    location,
    packages,
    trips,
    blogs,
    theam
  },
  devTools:true
})

export default store