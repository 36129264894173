import React from "react";
import {
  AccordionItem,
  AccordionItemHeading,
  AccordionItemButton,
  AccordionItemPanel,
} from "react-accessible-accordion";
import "react-accessible-accordion/dist/fancy-example.css";
import DOMPurify from "dompurify";

function AccordionCom({ header, content , expanded}) {
  function createMarkup(html) {
    return {
      __html: DOMPurify.sanitize(html),
    };
  }
  return (
      <AccordionItem className="accordion__item p-0 rounded-lg border-2 border-gray-300 mb-3">
        <AccordionItemHeading>
          <AccordionItemButton className="accordion__button p-3 pr-4 text-main-50 font-semibold ">
            {header}
          </AccordionItemButton>
        </AccordionItemHeading>
        <AccordionItemPanel>
          <div className="text-black font-normal break-words"
            dangerouslySetInnerHTML={createMarkup(content)}
          ></div>
        </AccordionItemPanel>
      </AccordionItem>
  );
}

export default AccordionCom;
