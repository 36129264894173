import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import AxiosInstance from "../AxiosInstance";

export const login = createAsyncThunk(
  "user/login",
  async (userData, { rejectWithValue }) => {
    try {
      const { data } = await AxiosInstance.post(`user/login`, userData);

      return data;
    } catch (error) {
      return rejectWithValue(error.response ? error.response.data : error )
    }
  }
);

export const getUser = createAsyncThunk(
  "user/:id",
  async (id, { rejectWithValue }) => {
    try {
      const { data } = await AxiosInstance.get(`user/${id}`);

      return data;
    } catch (error) {
      return rejectWithValue(error.response ? error.response.data : error )
    }
  }
);

export const updateUser = createAsyncThunk(
  "user/update",
  async ({ id , userData} , { rejectWithValue , dispatch }) => {
    try {

      const { data } = await AxiosInstance.put(`user/${id}`, userData);

      return data;
    } catch (error) {
      return rejectWithValue(error.response ? error.response.data : error )
    }
  }
);

export const forgetPass = createAsyncThunk(
  "user/forgetPass",
  async (email , { rejectWithValue }) => {
    try {

      const { data } = await AxiosInstance.get(`user/forGetPassword/${email}`);

      return data;
    } catch (error) {
      return rejectWithValue(error.response ? error.response.data : error )
    }
  }
);

const userSlice = createSlice({
  name: "user",
  initialState: {
    data: [],
    error: null,
    loading: false,
    isAuth:true,
    verifyNumber:null,
    userId:null
  },
  reducers:{
    setIsAuth:(state , action)=>{
      state.isAuth = action.payload
    }
  },
  extraReducers: (builder) => {
    builder
      // Get User 
      // #region
      .addCase(login.pending, (state) => {
        state.loading = true;
      })
      .addCase(login.fulfilled, (state, action) => {
        state.loading = false;
        state.error = null
      })
      .addCase(login.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
      // #endregion
      
      // Get User 
      // #region
      .addCase(getUser.pending, (state) => {
        state.loading = true;
      })
      .addCase(getUser.fulfilled, (state, action) => {
        state.loading = false;
        state.data = action.payload ? action.payload.data : [];
      })
      .addCase(getUser.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
      // #endregion

      // Update User
      // #region
      .addCase(updateUser.pending, (state) => {
        state.loading = true;
      })
      .addCase(updateUser.fulfilled, (state, action) => {
        state.loading = false;
        state.verifyNumber = null
      })
      .addCase(updateUser.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
      // #endregion

      // Forget pass
      // #region
      .addCase(forgetPass.pending, (state) => {
        state.loading = true;
      })
      .addCase(forgetPass.fulfilled, (state, action) => {
        state.loading = false;
        state.verifyNumber = action.payload.verifyNumber
        state.userId = action.payload.id
      })
      .addCase(forgetPass.rejected, (state, action) => {
        state.loading = false;
      })
      // #endregion
  },
});

export const { setIsAuth } = userSlice.actions
export default userSlice.reducer;
