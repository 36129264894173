import React from 'react'
import { BiError } from 'react-icons/bi'

function Error({message}) {
  return (
    <div className='w-full flex flex-col items-center justify-center gap-y-3 min-h-[40vh]'>
      <BiError size={50} className="text-error" />
      <h4>{message}</h4>
    </div>
  )
}

export default React.memo(Error);