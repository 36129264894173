import React from 'react'

function Conatiner({children}) {
  return (
    <div className='w-[80%] m-auto mt-5'>
        {children}
    </div>
  )
}

export default Conatiner