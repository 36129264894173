import React, { useState } from "react";
import { NavLink } from "react-router-dom";
import { IoIosArrowForward } from "react-icons/io";
import { CgArrowsV } from "react-icons/cg";
import { RiArrowDownSLine } from "react-icons/ri";
import { BsArrowsFullscreen } from "react-icons/bs";
import { FcHome } from "react-icons/fc";
import { TbPackages } from "react-icons/tb";
import { MdArticle } from "react-icons/md";

function SideBar() {
  const [open, setOpen] = useState(true);

  return (
    <div
      className={`h-screen ${
        open ? "w-60" : "w-20"
      } duration-500 pr-4 relative  border-r-2`}
    >
      {/* Start Button Arrow Felx Side  */}
      <span
        onClick={() => setOpen(!open)}
        className={`bg-white dark:bg-dark ${
          !open && "rotate-180"
        } p-1 shadow-sm shadow-slate-300  duration-500 text-dark-purple text-3xl rounded-full absolute -right-4 top-20 border border-dark-purple cursor-pointer`}
      >
        <IoIosArrowForward size={20} />
      </span>

      {/* Content SideBar  */}
      <nav className={`sideBar min-h-screen max-h-screen ${open ? 'overflow-y-auto' :''} relative pt-5`}>
        {/* Start Logo */}
        <div
          className={`border-solid ${
            !open && "scale-0"
          } duration-500 border-slate-400 border rounded-full w-48 m-auto  p-2 gap-x-1 flex justify-around items-center`}
        >
          <span>
            <span className="inline-block w-10 h-10">
              <img
                src={"/logo.jpeg"}
                alt="user"
                className="inline-block object-contain w-full h-full rounded-full "
              />
            </span>
          </span>
          <span className="font-bold">Egypt Tours</span>
          <span className="opacity-70">
            <CgArrowsV size={20} />
          </span>
        </div>
        <span
          className={`absolute top-5 ${
            open ? "-left-10" : "left-5"
          } duration-500`}
        >
          <img
                src={"logo.jpeg"}
                alt="user"
                className={`inline-block object-contain w-9 h-9 rounded-full 
                ${
                  !open ? "rotate-0" : "rotate-[360deg]"
                } duration-1000`}
              />
        </span>
        {/* End Logo */}

        {/* Home  */}
        <div className="relative">
          <ul>
            <li
              className={`border-b-2  border-slate-400 pb-2 m-3 font-medium ${
                !open && "scale-0"
              } duration-500`}
            >
              <NavLink to="/">Home</NavLink>
            </li>
            <li
              className={`absolute top-1 ${
                open ? "-left-10" : "left-5"
              } duration-500 `}
            >
              <FcHome size={30} />
            </li>
          </ul>
        </div>

        {/* Start Packages */}
        <div className="relative">
          <ul>
            <li
              className={`my-2 ${
                !open && "-translate-y-10 scale-0"
              } duration-200`}
            >
              <div className="relative overflow-hidden">
                <input
                  type="checkbox"
                  defaultChecked
                  className="peer absolute top-0 inset-x-0 w-full h-12 opacity-0 z-10 cursor-pointer"
                />
                <div className=" h-12 w-full pl-3 flex items-center">
                  <h1 className="text-[15px] font-bold">
                    Packages & Trips
                  </h1>
                </div>
                {/* Arrow Icon Products data */}
                <div className="absolute top-3 right-3 transition-transform duration-500 rotate-0 peer-checked:rotate-90">
                  <RiArrowDownSLine size={25} />
                </div>

                {/* Content Products data */}
                <div className="overflow-hidden transition-all duration-500 max-h-0 peer-checked:max-h-40">
                  <ul className="ml-3">
                    <li className="font-medium">
                      <NavLink to="allPackages">All Packages</NavLink>
                    </li>
                    <li className="font-medium">
                      <NavLink to="addPackage">Add Package</NavLink>
                    </li>
                    <li className="font-medium">
                      <NavLink to="customePackages">Custom Packages</NavLink>
                    </li>
                  </ul>
                </div>
              </div>
            </li>

            <li
              className={`absolute top-3 ${
                open ? "-left-10" : "left-6"
              } duration-200 `}
            >
              <TbPackages size={25} />
            </li>
          </ul>
        </div>

        {/* Start  Blogs */}
        <ul className="relative">
          <li
            className={`my-2 ${
              !open && "-translate-y-10 scale-0"
            } duration-200`}
          >
            <div className="relative overflow-hidden">
              <input
                type="checkbox"
                className="peer absolute top-0 inset-x-0 w-full h-12 opacity-0 z-10 cursor-pointer"
              />
              <div className="text-[15px] h-12 w-full pl-3 flex items-center">
                <h1 className="text-[15px] font-bold">Blogs</h1>
              </div>
              {/* Arrow Icon  Brand & Branches */}
              <div className="absolute top-3 right-3 transition-transform duration-500 rotate-0 peer-checked:rotate-90">
                <RiArrowDownSLine size={25} />
              </div>

              {/* Content  Brand & Branches */}
              <div className="overflow-hidden transition-all duration-500 max-h-0 peer-checked:max-h-48">
                <div>
                  <ul className="ml-3">
                    <li className="font-medium">
                      <NavLink to="allblogs">All Blogs</NavLink>
                    </li>
                    <li className="font-medium">
                      <NavLink to="addblog">Add Blog</NavLink>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </li>

          <li
            className={`absolute top-0 ${
              open ? "-left-10" : "left-6"
            } duration-200 `}
          >
            <MdArticle size={25}/>
          </li>
        </ul>

        {/* Start Others */}
        <ul className="relative">
          <li
            className={`my-2 ${
              !open && "-translate-y-10 scale-0"
            } duration-200`}
          >
            <div className="relative overflow-hidden">
              <input
                type="checkbox"
                className="peer absolute top-0 inset-x-0 w-full h-12 opacity-0 z-10 cursor-pointer"
              />
              <div className="text-[15px] h-12 w-full pl-3 flex items-center">
                <h1 className="text-[15px] font-bold">Outhers</h1>
              </div>
              {/* Arrow Icon  Brand & Branches */}
              <div className="absolute top-3 right-3 transition-transform duration-500 rotate-0 peer-checked:rotate-90">
                <RiArrowDownSLine size={25} />
              </div>

              {/* Content  Brand & Branches */}
              <div className="overflow-hidden transition-all duration-500 max-h-0 peer-checked:max-h-48">
                <div>
                  <ul className="ml-3">
                    <li className="font-medium">
                      <NavLink to="trips">Trips</NavLink>
                    </li>
                    <li className="font-medium">
                      <NavLink to="location">Location</NavLink>
                    </li>
                    <li className="font-medium">
                      <NavLink to="category">Category</NavLink>
                    </li>
                    <li className="font-medium">
                      <NavLink to="contactUs">Contact Us</NavLink>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </li>

          <li
            className={`absolute top-0 ${
              open ? "-left-10" : "left-6"
            } duration-200 `}
          >
            <BsArrowsFullscreen size={20} />
          </li>
        </ul>
      </nav>
    </div>
  );
}

export default SideBar;
