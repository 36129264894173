import React from "react";
import { Oval } from "react-loader-spinner";

function Loading() {
  return (
    <Oval
      height={80}
      width={80}
      color="#3730a3"
      wrapperStyle={{}}
      wrapperClass="w-full flex justify-center items-center min-h-[40vh]"
      visible={true}
      ariaLabel="oval-loading"
      secondaryColor="#E3F1FD"
      strokeWidth={4}
      strokeWidthSecondary={3}
    />
  );
}

export default React.memo(Loading);
