import React from "react";

function Input({icon , width , placeholder , onChange , type, register , defaultValue , accept , required , name}) {
  return (
    <div className={`relative max-w-full ${width} mb-3 rounded-l-md`}>
      <input
        name={name}
        placeholder={placeholder}
        className="p-2 border-2 pl-9 w-full rounded-l-md rounded-r-md focus:outline-none dark:text-black"
        onChange={(e)=>onChange(e)}
        type={type ? type : "text"}
        {...register}
        defaultValue={defaultValue ? defaultValue : ""}
        accept={accept ? accept : ''}
        autoComplete="true"
        required={required}
      />
      <span className="absolute left-0 top-0 bg-slate-200 h-full px-1 flex items-center rounded-l-md">
        {icon}
      </span>
    </div>
  );
}

export default Input;
