import React, { useCallback, useEffect, useState } from "react";
import Search from "../components/Search.com";
import TableData from "../components/TableData";
import TableHead from "../components/TableHead";
import { TbCategory, TbCategory2 } from "react-icons/tb";
import { MdOutlineUpdate } from "react-icons/md";
import { RxUpdate } from "react-icons/rx";
import Input from "../components/Input.com";
import { useForm, Controller } from "react-hook-form";
import ErrorValidation from "../components/addPackage/ErrorValidation";
import { useDispatch, useSelector } from "react-redux";
import {
  addCategories,
  deleteCategories,
  getAllCategories,
  searchCategories,
  updateCategories,
} from "../redux/slicers/category";
import { toast, ToastContainer } from "react-toastify";
import opthionsAlert from "../helper/opthionConfirmAlert";
import { confirmAlert } from "react-confirm-alert";
import Pagination from "../components/Pagination";
import Loading from "../components/Loading";
import Error from "../components/Error.com";
import ModalCom from "../components/Modal.com";
import Button from "../components/Button.com";
import Select from "react-select";
import { mainCategory } from "../helper/constants";

function Category() {
  const dispatch = useDispatch();
  const { data, loading, error } = useSelector((state) => state.category);
  const [pageNumper, setPageNumper] = useState(1);
  useEffect(() => {
    const controler = new AbortController();
    dispatch(getAllCategories({ pageNumper, signal: controler.signal }))
    
    return () => {
      controler.abort();
    };
  }, [dispatch, pageNumper]);
  const { register, handleSubmit, reset, control } = useForm({
    defaultValues: { name: "", maincategory: "" },
  });
  const handelDeleteCat = useCallback(
    (id) => {
      confirmAlert(
        opthionsAlert("Delete", "Are You Delete This Catigory", () => {
          dispatch(deleteCategories(id))
            .unwrap()
            .then((res) => {
              toast.success("Done Delete Catigory");
            })
            .catch((err) => toast.error(err.message));
        })
      );
    },
    [dispatch]
  );
  const onSubmit = (data) => {
    dispatch(addCategories(data))
      .unwrap()
      .then((res) => {
        if (res) {
          reset();
          toast.success("Done Add Category");
        }
      })
      .catch((err) => {
        toast.error(err.message);
      });
  };

  // Start Update
  // #region
  const [modalUpdate, setModalUpdate] = useState(false);
  const [updateData, setUpdateData] = useState({});
  const {
    register: updateRegister,
    handleSubmit: updateSupmit,
    formState: { errors: updateErr },
    control:updateControl,
    reset: resetUpdate,
  } = useForm({
    name:updateData?.name,
    maincategory:updateData?.maincategory
  });
  const handelBtnUpdate = (item) => {
    setUpdateData(item);
    setModalUpdate(true);
  };

  const handelUpdate = (data) => {
    const sendData = {
      id: updateData._id,
      catigoryData: data,
    };
    dispatch(updateCategories(sendData))
      .unwrap()
      .then((res) => {
        toast.success("Done Update Category");
        setModalUpdate(false);
      })
      .catch((err) => toast.error(err.message));
  };

  const handelSearch = ({ title }) => {
    const handelTitle = title.trim();
    dispatch(searchCategories(handelTitle))
      .unwrap()
      .then((res) => console.log(res));
  };
  // #endregion
  return (
    <div className="fadeIn p-5">
      <>
        <ToastContainer
          position="top-center"
          autoClose={2000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
          theme="light"
        />
        {/* Start Title  */}
        <div className="flex items-center">
          <span>
            <TbCategory size={35} className="text-main" />
          </span>
          <span className="px-3 font-medium text-2xl">Categoreis</span>
        </div>

        {/* Start Serch And Filter  */}
        <div className="flex flex-col items-start w-[96%]">
          <form
            onSubmit={handleSubmit(onSubmit)}
            className="flex flex-row justify-start items-baseline w-full flex-wrap gap-x-5 mt-3"
          >
            <div className="w-1/3">
              <Controller
                name="maincategory"
                control={control}
                render={({ field: { name, onChange, ref, value } }) => (
                  <Select
                    options={mainCategory}
                    ref={ref}
                    required
                    onChange={(e) => onChange(e.value)}
                    isSearchable={true}
                    placeholder="Select Main Catogry"
                    className=" border-2 text-[15px] rounded-l-md rounded-r-md focus:outline-none dark:text-black"
                  />
                )}
              />
            </div>
            <div className="flex gap-x-3 w-1/2">
              <div className="w-2/3">
                <Input
                  placeholder={"Enter Name Category"}
                  icon={<TbCategory2 size={20} className="text-main" />}
                  type="text"
                  required={true}
                  register={{
                    ...register("name"),
                  }}
                />
              </div>
              <Button value="Add Category" type="submit" />
            </div>
          </form>
          <Search placeholder={"Enter Name"} onSubmit={handelSearch} />
        </div>

        {error && <Error message={error.message} />}
        {loading && <Loading />}
        {/* Start Table  */}
        {!loading && !error && (
          <>
            <div className="w-full flex justify-center items-center">
              <div className="w-[80%]">
                <TableHead
                  titleArrary={["N", "Main Category", "Name", "Option"]}
                >
                  {!loading &&
                    data?.data &&
                    data?.data.map((item, index) => (
                      <TableData
                        number={index + 1}
                        one={item?.maincategory ? item?.maincategory : " "}
                        two={item?.name}
                        key={item._id}
                        deleteBtn={() => handelDeleteCat(item._id)}
                        showBtnUpdate={true}
                        onUpdate={() => handelBtnUpdate(item)}
                        showBtnRemove={true}
                      />
                    ))}
                </TableHead>
              </div>
            </div>
          </>
        )}
        {!error && (
          <Pagination
            totalPageNumper={data?.numOfPage}
            setPageNumper={setPageNumper}
            pageNumper={pageNumper}
          />
        )}

        <ModalCom
          onClose={() => {
            setUpdateData("");
            resetUpdate();
            setModalUpdate(false);
          }}
          icon={<MdOutlineUpdate size={25} className="text-main" />}
          title={`Update ${updateData.name}`}
          visible={modalUpdate}
        >
          <form
            onSubmit={updateSupmit(handelUpdate)}
            className="flex flex-col items-center min-h-[290px] justify-center gap-y-5"
          >
            <div className="flex justify-around gap-x-6 w-[90%]">
              <div className="w-1/2">
                <Controller
                  name="maincategory"
                  control={updateControl}
                  render={({ field: { onChange, ref } }) => (
                    <Select
                      options={mainCategory}
                      ref={ref}
                      required
                      defaultInputValue={updateData?.maincategory}
                      onChange={(e) => onChange(e.value)}
                      isSearchable={true}
                      placeholder="Select Main Catogry"
                      className=" border-2 text-[15px] rounded-l-md rounded-r-md focus:outline-none dark:text-black"
                    />
                  )}
                />
              </div>
              <div className="w-1/2">
                <Input
                  icon={<RxUpdate size={20} className="text-main" />}
                  defaultValue={updateData.name}
                  placeholder={"Enter Name"}
                  register={{
                    ...updateRegister("name", {
                      required: { value: true, message: "Please Enter Name" },
                    }),
                  }}
                />
                <ErrorValidation message={updateErr.name?.message} />
              </div>
            </div>
              
            <Button value="Update Category" type="submit" />
          </form>
        </ModalCom>
      </>
    </div>
  );
}

export default Category;
