/* eslint-disable eqeqeq */
import React, { useEffect, useState } from "react";
import { MdOutlineLogout, MdSettings } from "react-icons/md";
import { BiDownArrowAlt } from "react-icons/bi";
import "./animathion.css";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import ModalCom from "./Modal.com";
import Input from "./Input.com";
import { FaUserAlt } from "react-icons/fa";
import { MdEmail } from "react-icons/md";
import { RiLockPasswordFill } from "react-icons/ri";
import { BsMoonFill } from "react-icons/bs";
import { HiLightBulb } from "react-icons/hi";
import { useForm } from "react-hook-form";
import { schemaUpdateUser } from "../schemas/schemaUpdateUser";
import { yupResolver } from "@hookform/resolvers/yup";
import ErrorValidation from "./addPackage/ErrorValidation";
import { getUser, setIsAuth, updateUser } from "../redux/slicers/user";
import { toogelTheam } from "../redux/slicers/theam";
import Button from "./Button.com";
import { ToastContainer, toast } from "react-toastify";
import { userId } from "../helper/constants";
function OptionProfile() {
  const [toogel, setToogel] = useState(false);
  const dispatch = useDispatch();
  const navigathion = useNavigate();
  const { data } = useSelector((state) => state.user);
  const { theam } = useSelector((state) => state.theam);

  const [modalSettings, setModalSettings] = useState(false);
  const handelShowSettings = (_) => {
    reset();
    setModalSettings((prev) => !prev);
  };

  useEffect(() => {
    const getTheam = localStorage.getItem("theam");
    if (getTheam == "dark") {
      document.documentElement.classList.add("dark");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch]);

  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm({
    defaultValues: {
      name: data?.name,
      email: data?.email,
      password: "",
      confirmPassword: "",
    },
    resolver: yupResolver(schemaUpdateUser),
  });

  const handelTheam = () => {
    dispatch(toogelTheam());
  };

  const onSubmit = (data) => {
    const userData = {
      name: data.name,
      email: data.email,
      password: data.password,
    };
    const sendData = {
      id: userId,
      userData,
    };
    dispatch(updateUser(sendData))
      .unwrap()
      .then((res) => {
        if (res.statusCode === 200) {
          dispatch(getUser(userId))
            .unwrap()
            .then(() => {
              toast.success("Done Update Data");
              handelShowSettings();
            });
        }
      })
      .catch((err) => toast.error(err));
  };
  return (
    <>
      <ToastContainer
        position="top-center"
        autoClose={2000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
      />
      {toogel && (
        <div
          onClick={() => setToogel(false)}
          className="fixed bg-transparent w-[90vw] h-[90vh] z-10"
        ></div>
      )}
      <div className="relative border-b-2 border-slate-200 py-4 pr-10 dark:text-white  flex flex-row-reverse items-center justify-between p-9">
        <div className="w-fit flex justify-around items-center ml-auto gap-x-1">
          <span className="inline-block w-10 h-10 ">
            <img
              src={"logo.jpeg"}
              alt="user"
              className="inline-block object-contain w-full h-full rounded-full "
            />
          </span>
          <span className="inline-block text-slate-600 font-medium dark:text-white">
            {data?.name ? data?.name : "user"}
          </span>
          <span className="cursor-pointer" onClick={() => setToogel(!toogel)}>
            <BiDownArrowAlt
              size={20}
              className={`${
                toogel ? "rotate-90 " : ""
              }transition-all duration-500`}
            />
          </span>

          <div
            className={`w-[20%] right-3 ${
              toogel ? "h-auto py-4" : "h-0 overflow-hidden"
            } shadow-2xl z-20 duration-500 px-2
              absolute top-full bg-main-light rounded-xl  flex items-center justify-center`}
          >
            <span className="absolute bottom-full border-[14px] border-transparent border-b-main-light"></span>
            <ul className="w-full flex flex-col justify-center py-1 px-2 dark:text-black">
              <li
                onClick={handelShowSettings}
                className="mb-4 cursor-pointer flex flex-row justify-start items-center gap-x-1 font-bold border-b-2 border-b-white pb-1
                      hover:pl-1 transition-all duration-300
                    "
              >
                <MdSettings size={25} className="" />
                Settings
              </li>

              <li
                onClick={() => {
                  localStorage.clear();
                  navigathion("../login", { replace: true });
                  dispatch(setIsAuth());
                }}
                className="flex cursor-pointer flex-row justify-start items-center gap-x-1 font-bold border-b-2 border-b-white pb-1
                      hover:pl-1 transition-all duration-300
                  "
              >
                <MdOutlineLogout size={25} className="" />
                Log Out
              </li>
            </ul>
          </div>
        </div>

        {/* Theam Button */}
        <div className="shadow-md w-fit h-fit rounded-full dark:shadow-slate-500">
          <button
            onClick={handelTheam}
            className="w-fit p-3 rounded-full bg-slate-50 shadow-inner dark:bg-dark"
          >
            {theam == "dark" ? (
              <BsMoonFill size={20} className="text-white" />
            ) : (
              <HiLightBulb size={20} className="text-dark" />
            )}
          </button>
        </div>
      </div>

      <ModalCom
        onClose={handelShowSettings}
        title={"Update Data"}
        closeOnBackDroupPress={true}
        visible={modalSettings}
      >
        <form
          onSubmit={handleSubmit(onSubmit)}
          className="w-full flex flex-col justify-center items-center py-4"
        >
          <div className="w-4/5">
            <Input
              placeholder={"Enter Name"}
              icon={<FaUserAlt size={20} className="text-main" />}
              width="w-full"
              defaultValue={data.name}
              register={{ ...register("name") }}
            />
            <ErrorValidation message={errors.name?.message} mt={"mb-2"} />
          </div>
          <div className="w-4/5">
            <Input
              placeholder={"Enter Email"}
              icon={<MdEmail size={20} className="text-main" />}
              width="w-full"
              type={"email"}
              defaultValue={data.email}
              register={{ ...register("email") }}
            />
            <ErrorValidation message={errors.email?.message} mt={"mb-2"} />
          </div>

          <div className="w-4/5">
            <Input
              placeholder={"Enter Password"}
              icon={<RiLockPasswordFill size={20} className="text-main" />}
              width="w-full"
              type={"password"}
              register={{ ...register("password") }}
            />
            <ErrorValidation message={errors.password?.message} mt={"mb-2"} />
          </div>

          <div className="w-4/5">
            <Input
              placeholder={"Confirm Password"}
              icon={<RiLockPasswordFill size={20} className="text-main" />}
              width="w-full"
              type={"password"}
              register={{ ...register("confirmPassword") }}
            />
            <ErrorValidation
              message={errors.confirmPassword?.message}
              mt={"mb-2"}
            />
          </div>

          <Button value="Update" type="submit" />
        </form>
      </ModalCom>
    </>
  );
}

export default OptionProfile;
