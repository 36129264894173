import React from "react";
import {BiMessageAltError } from 'react-icons/bi'

function ErrorValidation({message, mt}) {
  return (
    <div>
      <div
        className={`${message? 'h-7' : 'h-0' }  m-auto ${mt?mt: 'mt-1'}  w-full ${
            message
            ? "border-red-300 bg-red-300"
            : "bg-transparent border-transparent"
        } duration-500 border-[1px] rounded`}
      >
        <p className="flex items-center h-full text-black duration-500 text-[12px] leading-6">
         <span className={`${message ? 'opacity-100' : 'opacity-0' } px-2 inline-block text-main border-r-[1px] border-red-200`}><BiMessageAltError size={20} /></span> 
        <span className={`px-2`}>{message}</span>  
        </p>
      </div>
    </div>
  );
}

export default React.memo(ErrorValidation);
