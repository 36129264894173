/* eslint-disable eqeqeq */
import React, { useEffect, useState } from "react";
import Input from "../components/Input.com";
import { RiLockPasswordFill } from "react-icons/ri";
import { MdEmail } from "react-icons/md";
import { VscPassFilled } from "react-icons/vsc";
import "./login.css";
import "../components/animathion.css";
import { useForm } from "react-hook-form";
import ErrorValidation from "../components/addPackage/ErrorValidation";
import { useDispatch, useSelector } from "react-redux";
import { forgetPass , login, updateUser } from "../redux/slicers/user";
import { ToastContainer, toast } from "react-toastify";
import { Oval } from "react-loader-spinner";
import ModalCom from "../components/Modal.com";
import Button from "../components/Button.com";
import { yupResolver } from "@hookform/resolvers/yup";
import { schemaResetPass } from "../schemas/schemaResetPass";

function Login() {
  const dispatch = useDispatch();
  const { loading, verifyNumber , userId } = useSelector((state) => state.user);
  const backgroundStyle = {
    backgroundImage: "url(login.webp)",
    backgroundSize: "cover",
    backgroundPosition: "center",
  };
  useEffect(() => {
    localStorage.removeItem("user");
  }, []);
  const {
    register,
    formState: { errors },
    handleSubmit,
  } = useForm({
    defaultValues: {
      email: "",
      password: "",
    },
  });

  const onSubmit = async (data) => {
    dispatch(login(data))
      .unwrap()
      .then((res) => {
        if (res.statusCode === 200) {
          toast.success("Welcome back, you will be transferred now");
          window.localStorage.setItem("user", JSON.stringify(res));
          window.location.replace("/")
        }
      })
      .catch((err) => {
        if (err.status) {
          return toast.error(err.message);
        }
        return toast.error("NetWork Error");
      });
  };

  // Start Forget Password
  const [modalForgetPass, setModalForgetPass] = useState(false);
  const handelModalPass = (_) => setModalForgetPass((prev) => !prev);

  const [mail, setMail] = useState("");
  const [number, setNumber] = useState("");
  const [showResetPass, setShowResetPass] = useState(false);

  const handelSendMail = (e) => {
    e.preventDefault();

    dispatch(forgetPass(mail))
      .unwrap()
      .then((res) => {})
      .catch((err) => {
        toast.error(err.message);
      });
  };

  const handelVerfyNumber = (e) => {
    e.preventDefault();
    if (verifyNumber === +number) {
      setShowResetPass(true);
    } else {
      toast.error("The number you entered is incorrect, please try again");
    }
  };

  const {
    register: resetRegister,
    handleSubmit: resetSubmit,
    formState: { errors: resetError },
    reset:resetPassword
  } = useForm({
    defaultValues: {
      password: "",
      confirmPassword: "",
    },
    resolver: yupResolver(schemaResetPass),
  });

  const handelResetSubmit = (data) => {
    const sendData = {
      id:userId,
      userData:{password:data.password}
    }
    dispatch(updateUser(sendData))
      .unwrap()
      .then((res) => {
        if(res.statusCode == 200){
          toast.success('Done Reset Password')
          setModalForgetPass(false)
          setShowResetPass(false)
          resetPassword()
        }
      })
      .catch((err) => toast.error(err.message))
  };
  return (
    <>
      <ToastContainer
        position="top-center"
        autoClose={2000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
      />
      <div
        className="relative w-full min-h-screen flex justify-center items-center"
        style={backgroundStyle}
      >
        {/* overLay */}
        <div className="absolute w-full h-full z-0 bg-[#03030314]"></div>
        {/* form */}
        <form
          onSubmit={handleSubmit(onSubmit)}
          className="w-[90%] sm:w-[60%] md:w-[50%] lg:w-[30%] xl:w-[30%] h-max md:px-4 lg:px-4 xl:px-4 py-10  z-10 
            rounded-xl bg-[#ffffff08] backdrop-blur-[15px] flex flex-col justify-center gap-y-1 items-center
            shadow-[0_0_40px_0_rgba(8,7,16,0.3)]
            "
        >
          <div className="w-full flex flex-col justify-center items-center gap-y-1 mb-4">
            <h1 className="font-bold  text-2xl tracking-wide">Welcome Back</h1>
            <p className="text-[16px] font-normal text-center">
              Enter your personal details and start journey with us
            </p>
          </div>
          <div className="w-[80%] h-auto flex-1">
            <Input
              icon={<MdEmail size={25} className="text-main" />}
              placeholder="Enter Your Email"
              type={"email"}
              register={{
                ...register("email", {
                  required: {
                    value: true,
                    message: "Please Enter Your Email",
                  },
                }),
              }}
            />
            <ErrorValidation message={errors.email?.message} mt={"mt-1"} />
          </div>
          <div className="w-[80%]">
            <Input
              icon={<RiLockPasswordFill size={25} className="text-main" />}
              placeholder="Enter Your Password"
              type={"password"}
              register={{
                ...register("password", {
                  required: {
                    value: true,
                    message: "Please Enter Your Password",
                  },
                }),
              }}
            />
            <ErrorValidation message={errors.password?.message} mt={"mt-1"} />
          </div>
          <button disabled={loading} className="button" type="submit">
            {!loading && "Login"}
            {loading && (
              <Oval
                width={25}
                height={25}
                color="#FFF"
                strokeWidth={6}
                secondaryColor="#EEE"
              />
            )}
          </button>

          {/* Forget Pass */}
          <h5
            onClick={handelModalPass}
            className="font-normal text-[16px] mt-3 tracking-wider cursor-pointer hover:font-bold hover:transition-all hover:duration-200"
          >
            Forget Password?
          </h5>
        </form>
      </div>

      <ModalCom
        visible={modalForgetPass}
        onClose={handelModalPass}
        icon={<RiLockPasswordFill size={22} className="text-main" />}
        title={"Forget Password"}
      >
        {/* Get MAil */}
        {!verifyNumber && (
          <form
            onSubmit={handelSendMail}
            className="w-full flex flex-col justify-center items-center py-5 gap-y-3"
          >
            <span className="w-fit h-fit p-5 rounded-full bg-main-light">
              <RiLockPasswordFill size={40} className="" />
            </span>
            <h2 className="dark:text-white text-xl">Forget Password</h2>
            <p className="text-center mb-3 text-base dark:text-white">
              Enter your email and we'll send <br />
              you a numper
            </p>
            <Input
              name={"email"}
              type={"email"}
              required={true}
              placeholder={"Enter Email"}
              icon={<MdEmail size={25} className="text-main" />}
              onChange={(e) => setMail(e.target.value)}
            />

            <Button type={"submit"} value={"Send"} />
          </form>
        )}

        {verifyNumber && !showResetPass && (
          <form
            onSubmit={handelVerfyNumber}
            className="fadeIn w-full flex flex-col justify-center items-center py-5 gap-y-3"
          >
            <span className="w-fit h-fit p-5 rounded-full bg-main-light">
              <RiLockPasswordFill size={40} className="" />
            </span>
            <h2 className="dark:text-white text-xl">Forget Password</h2>
            <p className="text-center mb-3 text-base dark:text-white">
              Enter your verify Number
            </p>
            <Input
              name={"verfyNumber"}
              type={"number"}
              required={true}
              placeholder={"Enter Verfy Numper"}
              icon={<MdEmail size={25} className="text-main" />}
              onChange={(e) => setNumber(e.target.value)}
            />

            <Button type={"submit"} value={"Send"} />
          </form>
        )}

        {showResetPass && (
          <form
            onSubmit={resetSubmit(handelResetSubmit)}
            className="fadeIn w-full flex flex-col justify-center items-center py-5 gap-y-3"
          >
            <span className="w-fit h-fit p-5 rounded-full bg-main-light">
              <VscPassFilled size={40} className="" />
            </span>
            <h2 className="dark:text-white text-xl">Reset Password</h2>
            <p className="text-center mb-3 text-base dark:text-white">
              Cheak Email Successfuly <br />
              you Can Reset Password Now
            </p>
            <div className="inline-grid">
              <Input
                type={"password"}
                placeholder={"Enter new Password"}
                icon={<MdEmail size={25} className="text-main" />}
                register={{ ...resetRegister("password") }}
              />
              {resetError.password?.message && (
                <ErrorValidation
                  message={resetError.password?.message}
                  mt={"-mt-2"}
                />
              )}
            </div>
            <div className="inline-grid">
              <Input
                name={"confirmpassword"}
                type={"password"}
                placeholder={"Enter Confirm Password"}
                icon={<MdEmail size={25} className="text-main" />}
                register={{ ...resetRegister("confirmPassword") }}
              />
              {resetError.confirmPassword?.message && (
                <ErrorValidation
                  message={resetError.confirmPassword?.message}
                  mt={"-mt-2"}
                />
              )}
            </div>
            <Button type={"submit"} value={"Reset"} />
          </form>
        )}
      </ModalCom>
    </>
  );
}

export default Login;
